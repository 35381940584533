import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
  color?: string;
  id?: string;
}

export const ProjectIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '35'}
      height={props.height ? props.height : '30'}
      id={props.id}
      viewBox="0 0 35 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3784 12.9176H15.9454V15.5656H17.3784V12.9176Z"
        fill={props.color || '#007298'}
      />
      <path
        d="M27.1219 16.4571H18.0897V17.8727H26.4059V21.9747H27.8388V17.1645C27.8388 16.7736 27.5184 16.4571 27.1219 16.4571Z"
        fill={props.color || '#007298'}
      />
      <path
        d="M15.2461 16.4571H7.30024C6.90454 16.4571 6.58337 16.7744 6.58337 17.1653V22.3256H8.01632V17.8727H15.2461V16.4571Z"
        fill={props.color || '#007298'}
      />
      <path
        d="M19.1071 16.6185L18.7225 16.5362C18.6892 16.4352 18.6479 16.3365 18.5988 16.2417L18.8113 15.9142C18.8494 15.8555 18.8406 15.7787 18.7915 15.7301L18.192 15.1379C18.142 15.0885 18.0643 15.0807 18.0056 15.1183L17.6741 15.3282C17.5782 15.2797 17.4783 15.2389 17.376 15.206L17.2927 14.8261C17.2776 14.7579 17.2166 14.7101 17.1468 14.7101H16.2999C16.2293 14.7101 16.169 14.7587 16.154 14.8261L16.0707 15.206C15.9684 15.2389 15.8685 15.2797 15.7725 15.3282L15.4411 15.1183C15.3816 15.0807 15.3039 15.0893 15.2547 15.1379L14.6552 15.7301C14.6052 15.7795 14.5973 15.8562 14.6354 15.9142L14.8479 16.2417C14.7987 16.3365 14.7575 16.4352 14.7242 16.5362L14.3396 16.6185C14.2706 16.6334 14.2222 16.6937 14.2222 16.7627V17.5993C14.2222 17.6691 14.2714 17.7286 14.3396 17.7435L14.7242 17.8257C14.7575 17.9268 14.7987 18.0255 14.8479 18.1203L14.6354 18.4478C14.5973 18.5065 14.606 18.5833 14.6552 18.6319L15.2547 19.2241C15.3047 19.2735 15.3824 19.2813 15.4411 19.2437L15.7725 19.0338C15.8685 19.0823 15.9684 19.1231 16.0707 19.156L16.154 19.5359C16.169 19.6041 16.2301 19.6519 16.2999 19.6519H17.1468C17.2174 19.6519 17.2776 19.6033 17.2927 19.5359L17.376 19.156C17.4783 19.1231 17.5782 19.0823 17.6741 19.0338L18.0056 19.2437C18.0651 19.2813 18.1428 19.2727 18.192 19.2241L18.7915 18.6319C18.8414 18.5825 18.8494 18.5057 18.8113 18.4478L18.5988 18.1203C18.6479 18.0255 18.6892 17.9268 18.7225 17.8257L19.1071 17.7435C19.1761 17.7286 19.2244 17.6683 19.2244 17.5993V16.7627C19.2252 16.6929 19.1761 16.6334 19.1071 16.6185ZM17.9192 17.181C17.9192 17.8328 17.3823 18.3624 16.7233 18.3624C16.0636 18.3624 15.5275 17.832 15.5275 17.181C15.5275 16.5292 16.0644 15.9996 16.7233 15.9996C17.3823 15.9996 17.9192 16.53 17.9192 17.181Z"
        fill={props.color || '#197AE5'}
      />
      <path
        d="M13.0765 21.0777H6.01328L4.3591 19.1819H0V28.9236C0.00158599 29.5174 0.488485 29.9984 1.08958 30H14.1661C14.7672 29.9984 15.2541 29.5174 15.2556 28.9236V21.0777H13.0765Z"
        fill={props.color || 'url(#paint0_linearTms)'}
      />
      <path
        d="M32.6859 21.0777H25.6227L23.9685 19.1819H19.6094V28.9236C19.611 29.5174 20.0979 29.9984 20.699 30H33.7755C34.3766 29.9984 34.8635 29.5174 34.865 28.9236V21.0777H32.6859Z"
        fill={props.color || 'url(#paint1_linearTms)'}
      />
      <path
        d="M24.5978 2.4936H15.3087L13.1335 0H7.40094V11.788C7.40332 12.5691 8.04327 13.2013 8.83388 13.2036H26.0308C26.8214 13.2013 27.4613 12.5691 27.4637 11.788V2.4936H24.5978Z"
        fill={props.color || 'url(#paint2_linearTms)'}
      />
      <defs>
        <linearGradient
          id="paint0_linearTms"
          x1="6.68693"
          y1="34.9668"
          x2="8.04383"
          y2="18.8773"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00B2D4" />
          <stop offset="0.6569" stopColor="#00CAE7" />
          <stop offset="1" stopColor="#00D8F3" />
        </linearGradient>
        <linearGradient
          id="paint1_linearTms"
          x1="26.1288"
          y1="36.8982"
          x2="27.4857"
          y2="20.8086"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00B2D4" />
          <stop offset="0.6569" stopColor="#00CAE7" />
          <stop offset="1" stopColor="#00D8F3" />
        </linearGradient>
        <linearGradient
          id="paint2_linearTms"
          x1="23.5487"
          y1="22.0279"
          x2="14.5054"
          y2="-0.315732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00B2D4" />
          <stop offset="0.6569" stopColor="#00CAE7" />
          <stop offset="1" stopColor="#00D8F3" />
        </linearGradient>
      </defs>
    </svg>
  );
};
