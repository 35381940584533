export const formatNumber = (n: number) => {
  if ((!n || n === null) && n !== 0) return "";
  return n.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatIntegerNumber = (n: number) => {
  if (!n || n === null) return "";
  return n.toLocaleString("de-DE", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};
