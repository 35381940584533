import { Theme, useTheme } from "@emotion/react";
import { CommandBarButton } from "@fluentui/react";

const saveBtnStyle = (theme: Theme) => {
  return {
    root: {
      height: "100%",
    },
    rootHovered: {
      backgroundColor: theme.palette.white,
    },
  };
};

const ToolbarButton = ({ disabled, operationFn, text, iconName }: any) => {
  const theme = useTheme();

  return (
    <CommandBarButton
      styles={saveBtnStyle(theme)}
      type="button"
      iconProps={{ iconName: iconName }}
      text={text}
      disabled={disabled}
      onClick={operationFn}
    />
  );
};

export default ToolbarButton;
