import { getTheme, IOverflowSetItemProps, OverflowSet } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import clmIcon from "../../../assets/icons/claim-form/CLM.svg";
import dmsIcon from "../../../assets/icons/claim-form/DMS.svg";
import tmsIcon from "../../../assets/icons/claim-form/TMS.svg";
import { sideBarStyle } from "../../config/ui";
import "./SideBar.scss";
import { SidebarContext } from "./SidebarContext";
import SidebarIconComponent from "./SidebarIconComponent/SidebarIconComponent";

function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useContext(SidebarContext);
  const theme = getTheme();
  const { t } = useTranslation();
  const [claimUrl, setClaimUrl] = useState("");

  const urlQuery = new URLSearchParams(location.search);
  const claimId = urlQuery.get("cid");
  const documentId = urlQuery.get("did");
  const isReadOnly = urlQuery.get("readOnly");

  let currentPage = location.pathname;
  useEffect(() => {
    if (isReadOnly) {
      setClaimUrl(`${location.pathname}${location.search}`);
    }
  }, [isReadOnly, location.pathname, location.search]);

  useEffect(() => {
    if (!isReadOnly && currentPage !== "dms" && currentPage !== "tms") {
      setClaimUrl("");
    }
  }, [currentPage, isReadOnly]);

  if (currentPage.includes("claim")) {
    currentPage = "claim";
  } else if (currentPage.includes("document")) {
    currentPage = "dms";
  } else if (currentPage.includes("task") && claimId && !documentId) {
    currentPage = "tms";
  }

  if (isReadOnly || currentPage === "dms" || currentPage === "tms") {
    return (
      <OverflowSet
        styles={{
          root: {
            ...sideBarStyle,
            backgroundColor: theme.palette.neutralLight,
          },
        }}
        role="menubar"
        vertical
        items={[
          {
            key: "claim",
            icon: clmIcon,
            name: currentPage,
            title: t("clm.sideBar.claim.title").replace(
              "{0}",
              state.internalClaimNumber
            ),
            openInNewTab: () => {
              window.open(
                `/claim/${state.claimId || claimId}?readOnly=true`,
                "_blank"
              );
            },
            onClick: () => {
              (state.claimId || claimId) &&
                currentPage !== "claim" &&
                navigate(
                  location.state
                    ? location.state.claimUrl
                    : `/claim/${state.claimId || claimId}?readOnly=true`,
                  location.state
                );
            },
          },
          {
            key: "dms",
            icon: dmsIcon,
            name: currentPage,
            title: t("clm.sideBar.documents.title"),
            openInNewTab: () => {
              window.open(
                `/document/?cid=${state.claimId || claimId}&ctype=Claim`,
                "_blank"
              );
            },
            onClick: () => {
              (state.claimId || claimId) &&
                currentPage !== "dms" &&
                navigate(
                  `/document/?cid=${state.claimId || claimId}&ctype=Claim`,
                  {
                    state: {
                      claimUrl: claimUrl
                        ? claimUrl
                        : `/claim/${state.claimId || claimId}?readOnly=true`,
                    },
                  }
                );
            },
          },
          {
            key: "tms",
            icon: tmsIcon,
            name: currentPage,
            title: t("clm.sideBar.tasks.title"),
            openInNewTab: () => {
              window.open(
                `/task/?cid=${state.claimId || claimId}&ctype=Claim`,
                "_blank"
              );
            },
            onClick: () => {
              (state.claimId || claimId) &&
                currentPage !== "tms" &&
                navigate(`/task/?cid=${state.claimId || claimId}&ctype=Claim`, {
                  state: {
                    claimUrl: claimUrl
                      ? claimUrl
                      : `/claim/${state.claimId || claimId}?readOnly=true`,
                  },
                });
            },
          },
        ]}
        onRenderOverflowButton={onRenderOverflowButton}
        onRenderItem={onRenderItem}
      />
    );
  } else {
    return null;
  }
}

export { SideBar as default };

export const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
  return <SidebarIconComponent item={item} />;
};

export const onRenderOverflowButton = (
  overflowItems: any[] | undefined
): JSX.Element => {
  return <></>;
};
