export function getRandomString(length: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const calculateDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0;
  } else {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;
    return dist;
  }
};

export function lightenDarkenColor(col: any, amt: any) {
  var usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export const deleteAllCookiesFromDomains = () => {
  var cookies = document.cookie.split("; ");
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split(".");
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      var p = window.location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }
};

export const deleteAllCookies = () => {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
};

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1).toLocaleLowerCase();
};

export const extractFileExtension = (str: string) => {
  const re = /(?:\.([^.]+))?$/;
  const arr = re.exec(str);
  if (arr) {
    return arr[1];
  }
};

export const emptyArr = [];

export function getCookieValue(a: string) {
  var b = document.cookie.match("(^|[^;]+)\\s*" + a + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}

export const isURL = (s: string) => {
  try {
    new URL(s);
  } catch (err) {
    return false;
  }
  return true;
};

export const getFileIcon = (type: any) => {
  let iconName = "TextDocumentShared";
  if (type === "pdf") {
    iconName = "PDF";
  } else if (type === "jpg" || type === "png" || type === "jpeg") {
    iconName = "Photo2";
  } else if (type === "msg") {
    iconName = "OutlookLogoInverse16";
  } else if (
    type === "xls" ||
    type === "xlsx" ||
    type === "xlsm" ||
    type === "csv"
  ) {
    iconName = "ExcelDocument";
  } else if (type === "zip") {
    iconName = "ZipFolder";
  } else if (type === "docx" || type === "docm") {
    iconName = "WordDocument";
  } else if (
    type === "pptx" ||
    type === "ppsx" ||
    type === "ppsm" ||
    type === "pptm"
  ) {
    iconName = "PowerPointDocument";
  } else if (type === "xml") {
    iconName = "FileCode";
  } else if (type === "avi" || type === "mov" || type === "mp4") {
    iconName = "MyMoviesTV";
  } else if (type === "vcf") {
    iconName = "ContactInfo";
  }

  return iconName;
};

export const getIconColor = (type: any) => {
  let color = "rgb(0, 120, 212)";
  if (type === "pdf") {
    color = "rgb(255, 47, 53)";
  } else if (type === "jpg" || type === "png" || type === "jpeg") {
    color = "#ff7600";
  } else if (
    type === "xls" ||
    type === "xlsx" ||
    type === "xlsm" ||
    type === "csv"
  ) {
    color = "#217346";
  } else if (type === "docx" || type === "docm") {
    color = "rgb(43, 87, 154)";
  } else if (
    type === "pptx" ||
    type === "ppsx" ||
    type === "ppsm" ||
    type === "pptm"
  ) {
    color = "#B7472A";
  }
  return color;
};

export const getFileExtension = (file: any) => {
  if (!file) return null;
  return file.substring(file.lastIndexOf(".") + 1).toLocaleLowerCase();
};
