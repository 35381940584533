import { css as cssClass } from "@emotion/css";
import { Dialog, DialogFooter, DialogType } from "@fluentui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { FormSpinner } from "../../../components/FormSpinner";
import PinLocationsList from "../../../components/PinLocationsList";
import { useSelector } from "../../../store/hooks";
import {
  approveLocationRoutine,
  loadLocationsForClientRoutine,
} from "../../../store/locations/locations";
import { ApproveDialogInfo } from "./ApproveDialogInfo";

type Props = {
  close: () => void;
};

const LocationInfoDialog = ({ close }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (s) => s.locations.approveLocationDialog.isLoading
  );

  const locationsSelection = useSelector((s) => s.locations.selection);

  const formObject = useMemo(() => {
    return locationsSelection && locationsSelection.length > 0
      ? locationsSelection[0]
      : null;
  }, [locationsSelection]);

  const onSubmit = async () => {
    await dispatch(
      approveLocationRoutine.trigger({
        clientId: formObject.clientId,
        locationId: formObject.locationId,
        onSuccess: () => {
          dispatch(
            loadLocationsForClientRoutine.trigger({
              clientId: formObject.clientId,
            })
          );
        },
      })
    );
    close();
  };

  const title = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {t("rvm.locationInfo.label")}
    </div>
  );

  return (
    <Dialog
      hidden={false}
      onDismiss={close}
      dialogContentProps={{
        type: DialogType.normal,
        title,
        className: cssClass`
        .ms-Dialog-title {
          margin: 20px;
          padding-bottom: 10px;
        }
        .ms-Dialog-inner {
          margin: 20px;
          padding-bottom: 14px;
        }
        .ms-Button {
          padding: 10px 35px;
          //background: rgb(0, 184, 238);
        }
      `,
        showCloseButton: true,
      }}
      minWidth={800}
      modalProps={{
        isBlocking: true, //phraseActivated ? false :
      }}
    >
      <ApproveDialogInfo />
      <PinLocationsList
        hideLocationName={true}
        selection={locationsSelection}
      />
      <DialogFooter></DialogFooter>
      {isLoading && <FormSpinner />}
    </Dialog>
  );
};

export default LocationInfoDialog;
