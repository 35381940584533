import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
}

export const MandateIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '22'}
      height={props.height ? props.height : '30'}
      viewBox="0 0 22 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1569 26.6004L4.15179 23.5675C3.82939 23.5102 3.59149 23.1983 3.59149 22.8308V2.1705C3.59149 1.78865 3.84826 1.46834 4.18625 1.42872L21.1913 0.00416441C21.5826 -0.0421969 21.9239 0.303406 21.9239 0.745946V25.8645C21.9231 26.3222 21.5588 26.672 21.1569 26.6004Z"
        fill="#007BD6"
      />
      <path
        d="M17.5654 29.9907L0.5603 26.9578C0.237902 26.9013 0 26.5886 0 26.2211V5.56079C0 5.17894 0.25677 4.85862 0.594755 4.81901L17.5998 3.39445C17.9911 3.34809 18.3324 3.69369 18.3324 4.13623V29.2548C18.3316 29.7125 17.9682 30.0623 17.5654 29.9907Z"
        fill="#009DE4"
      />
      <path
        d="M15.398 10.0975L2.24774 10.6277V8.13679L15.398 7.05531V10.0975Z"
        fill="#00C2F6"
      />
      <path
        d="M15.398 14.7926L2.24774 14.5111V12.0202L15.398 11.7513V14.7926Z"
        fill="#00C2F6"
      />
      <path
        d="M9.41843 19.0233L2.24774 18.4071V15.9162L9.41843 16.2146V19.0233Z"
        fill="#00C2F6"
      />
    </svg>
  );
};
