import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { DirectionalHint, Icon, Link } from "@fluentui/react";
import { Tooltip } from "@greco/components";
import { FunctionComponent } from "react";
import { NavLink, matchPath, matchRoutes, useLocation } from "react-router-dom";

import { NoAccess } from "../components/NoAccess";
import { Flex, HSpace } from "../components/styled";
import Locations from "../pages/locations/Locations";
import ValueMonitoring from "../pages/valueMonitoring/ValueMonitoring";

type RouteDef = {
  key: string;
  path: string;
  component: FunctionComponent<any>;
  getPath: (...args: any[]) => string;
  matchPath: (loc: any) => any;
  [key: string]: any;
};

type Routes = {
  valueMonitoring: RouteDef;
  valueMonitoringForLocation: RouteDef;
  valueMonitoringForLocationEdit: RouteDef;
  valueMonitoringForLastLocation: RouteDef;
  locations: RouteDef;
  noaccess: RouteDef;
};

export type RouteKey = keyof Routes;

const Crumb = ({
  path,
  text,
  final,
}: {
  path: string;
  text: string;
  final: boolean;
}) => {
  return (
    <StyledClientBreadcrumb to={path}>
      <Flex
        css={css`
          flex-wrap: nowrap;
          overflow: hidden;
        `}
      >
        <HSpace />
        <Link
          css={css`
            font-size: 12px;
            text-overflow: ellipsis;
          `}
        >
          <Tooltip directionalHint={DirectionalHint.leftTopEdge} content={text}>
            {text}
          </Tooltip>
        </Link>
        <HSpace />
        {!!!final ? <DividerIcon iconName="Line" /> : null}
      </Flex>
    </StyledClientBreadcrumb>
  );
};

const DividerIcon = styled(Icon)`
  transform: rotateZ(70deg);
  user-select: none;
  color: ${(p) => p.theme.palette.themePrimary};
  font-size: 12px;
`;

const StyledClientBreadcrumb = styled(NavLink)`
  color: ${(p) => p.theme.palette.neutralPrimary};
  text-decoration: none;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
`;

export const routes: Routes = {
  valueMonitoring: {
    key: "valueMonitoring",
    path: "/value-monitoring",
    getPath: () => "/value-monitoring",
    component: ValueMonitoring,
    matchPath: (loc) => matchPath("/value-monitoring", loc.pathname),
  },
  valueMonitoringForLastLocation: {
    key: "valueMonitoringForLastLocation",
    path: "/value-monitoring/:clientId/:locationId",
    getPath: (clientId: number, locationId: number) =>
      `/value-monitoring/${clientId}/${locationId}`,
    component: ValueMonitoring,
    matchPath: (loc) =>
      matchPath("/value-monitoring/:clientId/:locationId", loc.pathname),
  },
  valueMonitoringForLocation: {
    key: "valueMonitoringForLocation",
    path: "/value-monitoring/:clientId/:locationId/:propertyInformationId",
    getPath: (
      clientId: number,
      locationId: number,
      propertyInformationId: number
    ) => `/value-monitoring/${clientId}/${locationId}/${propertyInformationId}`,
    component: ValueMonitoring,
    matchPath: (loc) =>
      matchPath(
        "/value-monitoring/:clientId/:locationId/:propertyInformationId",
        loc.pathname
      ),
  },
  valueMonitoringForLocationEdit: {
    key: "valueMonitoringForLocationEdit",
    path: "/value-monitoring/:clientId/:locationId/:propertyInformationId/edit",
    getPath: (
      clientId: number,
      locationId: number,
      propertyInformationId: number
    ) =>
      `/value-monitoring/${clientId}/${locationId}/${propertyInformationId}/edit`,
    component: ValueMonitoring,
    matchPath: (loc) =>
      matchPath(
        "/value-monitoring/:clientId/:locationId/:propertyInformationId/edit",
        loc.pathname
      ),
  },
  locations: {
    key: "locations",
    path: "/locations",
    getPath: (clientId: string) => `/locations?clientId=${clientId}`,
    component: Locations,
    matchPath: (loc) => matchPath("/locations", loc.pathname),
  },
  noaccess: {
    key: "noaccess",
    path: "/noaccess",
    getPath: () => "/noaccess",
    component: NoAccess,
    matchPath: (loc) => matchPath("/noaccess", loc.pathname),
  },
};

export const useCurrentRoute = () => {
  const location = useLocation();
  // const matchResult = useMatch(location.pathname);
  const realRoutes = Object.keys(routes).map((route) => {
    return {
      path: routes[route].path,
    };
  });
  const matchResult = matchRoutes(realRoutes, location);

  return Object.values(routes).reduce((match: any | null, r: any) => {
    if (matchResult[0].route.path === r.path) {
      return r.key;
    }
    return match;
  }, null);
};
