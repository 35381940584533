import React from "react";
import { useTranslation } from "react-i18next";
import { Flex } from "../../../components/styled";
import DocumentDropzone from "./DocumentDropzone";
import DocumentInputField from "./DocumentInputField";
import { sectionClasses } from "./DocumentList";
import { IDocument } from "./types";

type Props = {
  externalDocuments: IDocument[];
  internalDocuments: IDocument[];
  handleDeleteDocument: (url: string, internalDocType: boolean) => void;
  handleSetConfigurationSettings: (settings) => void;
  handleUpdateConfigurationSettings: () => void;
};

function InternalDocuments({
  externalDocuments,
  internalDocuments,
  handleDeleteDocument,
  handleSetConfigurationSettings,
  handleUpdateConfigurationSettings,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className={sectionClasses.sectionWrap}>
      <div className={sectionClasses.sectionTitle}>
        {t("rvm.internalDocuments.label")}
      </div>
      {internalDocuments?.map((doc, index) => {
        return (
          <Flex
            justifyContent="space-between"
            gap={20}
            alignItems="center"
            key={`${doc.Url} ${index}`}
          >
            <DocumentInputField
              key={`${doc.Url} ${index}`}
              handleDelete={() => {
                handleDeleteDocument(doc.Url, true);
              }}
              handleUpdateConfigurationSettings={
                handleUpdateConfigurationSettings
              }
              defaultValue={doc.Name}
              handleChange={(event) => {
                const value = event.target.value;
                const InternalUserDocuments = internalDocuments.map((item) => {
                  if (item.Url === doc.Url) {
                    return {
                      Name: value,
                      Url: item.Url,
                    };
                  }
                  return item;
                });

                handleSetConfigurationSettings({
                  settingValue: {
                    ExternalUserDocuments: externalDocuments,
                    InternalUserDocuments,
                  },
                });
              }}
            />
          </Flex>
        );
      })}
      <div>
        <DocumentDropzone
          handleSetConfigurationSettings={handleSetConfigurationSettings}
          isInternalUserDocument={true}
        />
      </div>
    </div>
  );
}

export default InternalDocuments;
