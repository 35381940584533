import { Label } from "@fluentui/react";
import { useField } from "formik";
import { VSpace } from "../Spacer";
import { ErrorMessage, StyledCheckbox } from "../styled";
import { CheckboxFieldConfig } from "./types";
import { useDynamicConfig } from "./useDynamicConfig";

type Props = { fieldConfig: CheckboxFieldConfig };

export const CheckboxField = ({ fieldConfig }: Props) => {
  const config = {
    ...fieldConfig,
    ...useDynamicConfig(fieldConfig.d),
  };
  const { name, label, isVisible = true, isDisabled, isRequired } = config;
  const [field, { error }, { setValue }] = useField({
    name,
    type: "checkbox",
  });

  if (!isVisible) return null;

  return (
    <>
      <Label required={isRequired} disabled={isDisabled}>
        {label}
      </Label>
      <StyledCheckbox
        {...field}
        onChange={(e, checked) => setValue(checked)}
        disabled={isDisabled}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <VSpace height={10} />
    </>
  );
};
