import {
  DirectionalHint,
  getTheme,
  Icon,
  IIconStyles,
  TooltipHost,
} from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { statusColors } from "../../../library/constants";
import { useSelector } from "../../../store/hooks";
// import { Iprops } from 'components/Crm/Types/CircleFillHoverTypes';
// import { CrmContext, CrmTaxonomyTableItem } from 'contexts/Crm/CrmContext';
// import { serviceStatusColor } from 'config/colors';
// import { CircleFillHover } from 'components/Crm/CommonComponents/CircleHover/CircleFillHover';

export const ServiceStatusCircle = (
  props: any // Omit<Iprops, "circleColors">
): JSX.Element => {
  const { t } = useTranslation();
  const theme = getTheme();
  const taxonomy = useSelector((s) => s.taxonomy);

  const circleStyles = (color: string): IIconStyles => {
    return {
      root: {
        fontSize: 9,
        width: "5px",
        height: "5px",
        color: color,
      },
    };
  };

  return (
    <TooltipHost
      hostClassName={"tooltip"}
      calloutProps={
        props.tooltipHostCalloutProps ? props.tooltipHostCalloutProps : {}
      }
      tooltipProps={{
        onRenderContent: () => {
          return (
            <p>
              {t(
                taxonomy.PropertyInformationStatus.byId[
                  props.item.location.statusCode
                ].code
              )}
            </p>
          ); //<SimpleList data={tooltipData} />;
        },
      }}
      delay={500}
      closeDelay={props.closeDelay ? props.closeDelay : 0}
      directionalHint={
        props.directionalHint
          ? props.directionalHint
          : DirectionalHint.topCenter
      }
      styles={props.tooltipHostStyles ? props.tooltipHostStyles : {}}
    >
      <Icon
        iconName={
          statusColors[
            taxonomy.PropertyInformationStatus.byId[
              props.item.location.statusCode
            ].code
          ].shape
        }
        data-type={"status-circle"}
        data-status={
          taxonomy.PropertyInformationStatus.byId[
            props.item.location.statusCode
          ].code
        }
        styles={circleStyles(
          statusColors[
            taxonomy.PropertyInformationStatus.byId[
              props.item.location.statusCode
            ].code
          ].color
        )} //
      />
    </TooltipHost>
  );
};
