import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ListContextMenuIconButton } from "../../../components/styled";
import { routes } from "../../../config/routes";
import { useSelector } from "../../../store/hooks";
import { setDeleteDialog } from "../../../store/locations/locations";
import { AppDispatch } from "../../../store/store";

type Props = {
  row?: any;
};

export const ContextButton = ({ row }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const taxonomy = useSelector((s) => s.taxonomy);

  if (!row) return null;
  const status_nodata =
    taxonomy?.PropertyInformationStatus.byId[row.location.statusCode].code !==
    "PropertyInformationStatus.NO_DATA";

  const isDisabledStatus =
    taxonomy?.PropertyInformationStatus.byId[row.location.statusCode].code ===
    "PropertyInformationStatus.DISABLED";

  const status_invitation_sent =
    taxonomy?.PropertyInformationStatus.byId[row.location.statusCode].code !==
    "PropertyInformationStatus.INVITATION_SENT";

  const pi_status_invitation_sent =
    taxonomy?.PropertyInformationStatus.byId[row.statusCode].code ===
    "PropertyInformationStatus.INVITATION_SENT";

  const onClickShowLocations = () => {
    navigate(
      routes.valueMonitoringForLocationEdit.getPath(
        row.clientId,
        row.locationId,
        row.propertyInformationId
      )
    );
  };

  const onClickDeleteLocation = () => {
    dispatch(
      setDeleteDialog({
        clientId: row.clientId,
        locationId: row.locationId,
        rowVersion: row.rowVersion,
        isOpen: true,
        isLoading: false,
      })
    );
  };

  const items = [
    {
      key: "showLocations",
      text: t("rvm.edit.label"),
      iconProps: { iconName: "SingleColumnEdit" },
      disabled:
        !status_nodata ||
        pi_status_invitation_sent ||
        isDisabledStatus ||
        row.propertyInformationId === null,
      onClick: onClickShowLocations,
    },
    {
      key: "deleteLocation",
      text: t("rvm.delete.label"),
      iconProps: { iconName: "PageRemove" },
      disabled: !status_nodata || row.propertyInformationId === null,
      onClick: onClickDeleteLocation,
    },
  ];

  return (
    <ListContextMenuIconButton
      iconProps={{ iconName: "MoreVertical" }}
      menuIconProps={{ style: { display: "none" } }}
      menuProps={{
        items: items,
      }}
    />
  );
};
