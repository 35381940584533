import moment from "moment";
import { useState } from "react";

import { useTheme } from "@emotion/react";
import { Dialog, DialogType, Icon, mergeStyleSets } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";
import LogPersona from "../LogPersona";

type Props = {
  item: any;
};

function HistoryComments({ item }: Props) {
  const theme = useTheme();

  const { t } = useTranslation();
  const [showCommentsDialog, setShowCommentsDialog] = useState(false);

  const classes = getClasses(theme);

  const toggleCommentsDialog = (e) => {
    e.stopPropagation();
    setShowCommentsDialog((prev) => !prev);
  };

  const sortedCommentHistoryData = [
    ...item?.propertyInformationCommentHistories,
  ]?.sort((a, b) => moment(b?.setAt).diff(moment(a?.setAt)));

  return (
    <>
      <Icon
        onClick={toggleCommentsDialog}
        className={classes.icon}
        iconName="Comment"
      />

      <Dialog
        hidden={!showCommentsDialog}
        onDismiss={toggleCommentsDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: <div>{t("rvm.history.label")}</div>,
        }}
        minWidth={800}
        modalProps={{
          isBlocking: true, //phraseActivated ? false :
        }}
      >
        {sortedCommentHistoryData.map((commentObj, index) => {
          const isGrecoUser = commentObj.isGrEcoComment;

          const userType = isGrecoUser
            ? t("rvm.grecoUser.label")
            : t("rvm.clientUser.label");

          const createdBy =
            commentObj?.setByUserName || commentObj?.setByUserEmail || userType;

          const createdAt = moment(commentObj.setAt)
            .locale(i18n.language)
            .format("DD. MMMM YYYY, H:mm:ss");

          const commentTypeClass = isGrecoUser
            ? classes.grecoComment
            : classes.clientComment;

          const commentClass = `${classes.comment} ${commentTypeClass}`;

          const commentClassPadding = isGrecoUser ? "" : classes.paddingRight;

          return (
            <div
              className={`${classes.commentWrap}`}
              key={`${commentObj.rowVersion} ${index}`}
            >
              <div className={commentClass}>
                <LogPersona name={`${createdBy} - ${createdAt} `} />
              </div>
              <div className={`${commentClass} ${commentClassPadding}`}>
                {commentObj.comment}
              </div>
              <div className={classes.divider}></div>
            </div>
          );
        })}
      </Dialog>
    </>
  );
}

export default HistoryComments;

const getClasses = (theme) =>
  mergeStyleSets({
    icon: {
      cursor: "pointer",
      fontSize: "20px",
    },
    commentWrap: {
      display: "flex",
      flexDirection: "column",
    },
    comment: {
      display: "flex",
      width: "100%",
    },
    grecoComment: {
      justifyContent: "flex-start",
    },
    clientComment: {
      justifyContent: "flex-end",
    },
    paddingRight: {
      paddingRight: "20px",
    },
    divider: {
      border: `1px solid ${theme?.palette?.neutralLighter}`,
      height: "auto",
      width: "100%",
      margin: "20px 0 10px",
    },
  });
