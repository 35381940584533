import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
  color?: string;
}

export const RvmIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '26'}
      height={props.height ? props.height : '26'}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.74561 0L20.6885 2.80184e-06C23.0695 3.22029e-06 24.9997 1.9401 24.9997 4.33334L24.9997 23.8333C24.9997 25.03 24.0346 26 22.8441 26L6.90122 26C5.71071 26 4.74561 25.0299 4.74561 23.8333L4.74561 0Z"
        fill="#E54E5C"
      />
      <path
        d="M16.9358 5.60221V4.30938C16.9358 1.92937 18.8742 1.22767e-06 21.2653 1.43574e-06L4.76595 0C2.37487 -2.08066e-07 0.436524 1.92937 0.436524 4.30938L0.436523 5.60221L16.9358 5.60221Z"
        fill="#AD0016"
      />
      <path
        d="M7.33154 7.76097C7.33154 7.52084 7.52448 7.32617 7.76248 7.32617H21.9835C22.2215 7.32617 22.4144 7.52084 22.4144 7.76097V8.63057C22.4144 8.87071 22.2215 9.06537 21.9835 9.06537H7.76248C7.52448 9.06537 7.33154 8.87071 7.33154 8.63057V7.76097Z"
        fill="#FF98A1"
      />
      <path
        d="M7.37236 12.0163C7.37236 11.7762 7.5653 11.5815 7.8033 11.5815H12.5436C12.7816 11.5815 12.9746 11.7762 12.9746 12.0163V12.8859C12.9746 13.126 12.7816 13.3207 12.5436 13.3207H7.8033C7.5653 13.3207 7.37236 13.126 7.37236 12.8859V12.0163Z"
        fill="#FF98A1"
      />
      <path
        d="M7.37236 16.3643C7.37236 16.1242 7.5653 15.9295 7.8033 15.9295H12.5436C12.7816 15.9295 12.9746 16.1242 12.9746 16.3643V17.2339C12.9746 17.474 12.7816 17.6687 12.5436 17.6687H7.8033C7.5653 17.6687 7.37236 17.474 7.37236 17.2339V16.3643Z"
        fill="#FF98A1"
      />
      <path
        d="M15.9816 18.9586L18.5725 19.8222V22.8448L15.9816 24.5721V18.9586Z"
        fill="#9B0014"
      />
      <path
        d="M18.5725 19.8222L21.1633 18.9586V24.5721L18.5725 22.8448V19.8222Z"
        fill="#9B0014"
      />
      <path
        d="M22.8905 15.5041C22.8905 16.0712 22.7789 16.6327 22.5619 17.1566C22.3448 17.6805 22.0268 18.1565 21.6258 18.5575C21.2248 18.9584 20.7488 19.2765 20.2249 19.4935C19.701 19.7105 19.1395 19.8222 18.5725 19.8222L18.5725 15.5041H22.8905Z"
        fill="#9B0014"
      />
      <path
        d="M18.5725 11.186C19.1395 11.186 19.701 11.2977 20.2249 11.5147C20.7488 11.7317 21.2248 12.0498 21.6258 12.4508C22.0268 12.8517 22.3448 13.3278 22.5619 13.8517C22.7789 14.3755 22.8905 14.9371 22.8905 15.5041H18.5725L18.5725 11.186Z"
        fill="#9B0014"
      />
      <path
        d="M14.2544 15.5041C14.2544 14.9371 14.3661 14.3755 14.5831 13.8517C14.8001 13.3278 15.1182 12.8517 15.5191 12.4508C15.9201 12.0498 16.3961 11.7317 16.92 11.5147C17.4439 11.2977 18.0054 11.186 18.5725 11.186L18.5725 15.5041L14.2544 15.5041Z"
        fill="#9B0014"
      />
      <path
        d="M18.5725 19.8222C18.0054 19.8222 17.4439 19.7105 16.92 19.4935C16.3961 19.2765 15.9201 18.9584 15.5191 18.5575C15.1182 18.1565 14.8001 17.6805 14.5831 17.1566C14.3661 16.6327 14.2544 16.0712 14.2544 15.5041L18.5725 15.5041L18.5725 19.8222Z"
        fill="#9B0014"
      />
      <path
        d="M20.2595 15.5138C20.2595 16.4658 19.4878 17.2376 18.5358 17.2376C17.5838 17.2376 16.812 16.4658 16.812 15.5138C16.812 14.5618 17.5838 13.79 18.5358 13.79C19.4878 13.79 20.2595 14.5618 20.2595 15.5138Z"
        fill="#E54E5C"
      />
    </svg>
  );
};
