import React, { useState } from 'react';

const SidebarContext = React.createContext<any>({});
const SidebarConsumer = SidebarContext.Consumer;

export interface ISideBarContextState {
  claimId?: number;
  claimName?: string;
  policyNumber?: number;
  clientName?: string;
  clientId?: number;
  isOrganization?: boolean;
  internalClaimNumber?: number;
}

function SidebarProvider({ children }: any) {
  const [claimData, setClaimData] = useState<ISideBarContextState>({});

  return (
    <SidebarContext.Provider
      value={{
        state: { ...claimData },
        setClaimData: setClaimData
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
}

export { SidebarProvider as default, SidebarConsumer, SidebarContext };
