import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
}

export const DmsIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
        d="M20.9535 1.37036H7.92188V11.8277C7.92188 11.9915 7.98656 12.1548 8.10119 12.2787L8.09628 12.2836C8.11143 12.2987 8.13108 12.3182 8.15114 12.3284C8.27068 12.4376 8.42544 12.5069 8.60967 12.5069H22.1546C22.4985 12.5069 22.8424 12.2241 22.8424 11.8228V1.37036H20.9535Z"
        fill="#BDEEF9"
      />
      <path
        d="M10.6157 2.65625H19.9414V3.48128H10.6157V2.65625ZM10.6157 5.54325H19.9414V6.36828H10.6157V5.54325ZM10.6157 8.2245H19.9414V9.04953H10.6157V8.2245Z"
        fill="white"
      />
      <path
        d="M9.81207 1.37057H22.8428V0.686508C22.8428 0.34305 22.5587 0 22.1546 0H8.61007C8.26618 0 7.92188 0.283567 7.92188 0.686508V1.37057H9.81207Z"
        fill="#E15815"
      />
      <path
        d="M20.8204 1.37052H7.92354V11.893C7.71434 12.0625 7.78476 12.2373 7.91003 12.3669C8.03039 12.4964 22.7151 1.37012 22.7151 1.37012L20.8204 1.37052Z"
        fill="#C5FDF4"
      />
      <g opacity="0.44">
        <rect
          x="16.4615"
          y="17.1179"
          width="21.7248"
          height="4.76173"
          transform="rotate(-90 16.4615 17.1179)"
          fill="url(#pattern0)"
        />
      </g>
      <path
        d="M19.2781 3.75415H6.24695V14.2115C6.24695 14.3753 6.31163 14.5386 6.42627 14.6625L6.42135 14.6674C6.4365 14.6825 6.45615 14.702 6.47621 14.7122C6.59576 14.8214 6.75051 14.8907 6.93474 14.8907H20.4797C20.8236 14.8907 21.1675 14.6079 21.1675 14.2066V3.75415H19.2781Z"
        fill="#BDEEF9"
      />
      <path
        d="M8.94086 5.04004H18.2666V5.86507H8.94086V5.04004ZM8.94086 7.92704H18.2666V8.75207H8.94086V7.92704ZM8.94086 10.6083H18.2666V11.4329H8.94086V10.6083Z"
        fill="white"
      />
      <path
        d="M8.13673 3.75412H21.1675V3.07005C21.1675 2.7266 20.8834 2.38354 20.4793 2.38354H6.93515C6.59125 2.38354 6.24695 2.66711 6.24695 3.07005V3.75412H8.13673Z"
        fill="#E15815"
      />
      <path
        d="M19.1451 3.75431H6.24862V14.2768C6.03941 14.4463 6.10983 14.6211 6.2351 14.7507C6.35547 14.8802 21.0402 3.75391 21.0402 3.75391L19.1451 3.75431Z"
        fill="#C5FDF4"
      />
      <path
        d="M23.0647 5.3269H5.32712C4.55827 5.3269 3.93475 5.94741 3.93475 6.71255V20.69H23.4369C24.001 20.69 24.4583 20.2349 24.4583 19.6735V6.71296C24.4579 5.94741 23.8344 5.3269 23.0647 5.3269Z"
        fill="#D13600"
      />
      <path
        d="M21.7317 5.3269H5.32712C4.55827 5.3269 3.93475 5.94741 3.93475 6.71255V20.69H21.7317V5.3269Z"
        fill="#E15815"
      />
      <path
        d="M14.2279 10.3879H11.6159C10.9654 10.3879 10.3795 9.92672 10.2985 9.28422C10.2014 8.51704 10.8065 7.85742 11.559 7.85742H14.1738C14.8235 7.85742 15.4086 8.31944 15.4884 8.96113C15.5842 9.7279 14.9869 10.3879 14.2279 10.3879Z"
        fill="#D13600"
      />
      <path
        d="M5.3099 20.6733C4.539 20.6733 3.91385 20.0512 3.91385 19.284V13.4929C1.6552 14.1281 0 16.1945 0 18.6456C0 21.6027 2.4089 24 5.38032 24C7.63037 24 9.55782 22.6249 10.3607 20.6733H7.20214H5.3099Z"
        fill="#E15815"
      />
      <path
        d="M5.38071 13.2917C4.87224 13.2917 4.38055 13.3618 3.91425 13.493V19.2841C3.91425 20.0513 4.5394 20.6734 5.3103 20.6734H7.20254H10.361C10.6186 20.048 10.7606 19.3636 10.7606 18.6457C10.7606 15.689 8.35213 13.2917 5.38071 13.2917Z"
        fill="#D13600"
      />
      <path
        d="M7.07315 17.1262H2.36548V20.8476C2.36548 21.0065 2.49485 21.1348 2.6541 21.1348H7.44735C7.60701 21.1348 7.73597 21.0061 7.73597 20.8476V17.1262H7.07315Z"
        fill="white"
      />
      <path
        d="M3.79553 17.8689H6.48078V18.4629H3.79553V17.8689Z"
        fill="#E15815"
      />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0" />
        </pattern>
        <image
          id="image0"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAe4AAACYCAYAAAAiGwo+AAAACXBIWXMAAAsSAAALEgHS3X78AAAA"
        />
      </defs>
    </svg>
  );
};
