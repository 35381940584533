import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
  color?: string;
}

export const ContactPersonIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '31'}
      height={props.height ? props.height : '30'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1722 14.3586L17.15 14.3323C16.3683 13.4066 15.378 12.6948 14.2669 12.2362C13.1263 13.3033 11.5948 13.9575 9.91324 13.9575C8.25846 13.9575 6.74911 13.3238 5.61442 12.2867C4.55151 12.7462 3.60377 13.4398 2.85006 14.3323L2.82771 14.3588C1.61492 15.7949 1 17.4259 1 19.2064V19.7884H19V19.2064C19 17.4259 18.3851 15.7949 17.1722 14.3586Z"
        fill={props.color || '#00B8EE'}
      />
      <path
        d="M3.71806 9.3122H3.77444H4.99519H4.99554C4.86451 8.94146 4.77426 8.5517 4.7303 8.14817H4.73061H9.16127V9.3122H4.99554C5.08203 9.55699 5.18671 9.79321 5.30723 10.0198C5.68895 10.7389 6.24209 11.3653 6.90625 11.8359C7.75653 12.4385 8.79402 12.7935 9.91323 12.7935C11.0445 12.7935 12.0925 12.4312 12.9479 11.8168C13.4876 11.4279 13.9539 10.9374 14.3115 10.3762C14.5228 10.045 14.698 9.68849 14.8309 9.31224H14.831H16.052H16.1565C16.8992 9.31224 17.5034 8.70803 17.5034 7.96531V7.56616C17.5034 3.39418 14.1092 0 9.93725 0C5.76527 0 2.37109 3.39418 2.37109 7.56616V7.96527C2.37113 8.70799 2.97538 9.3122 3.71806 9.3122ZM9.93729 1.16402C12.8623 1.16402 15.3351 3.13596 16.097 5.82012H14.8214C14.0981 3.80815 12.1712 2.36534 9.91327 2.36534C7.65534 2.36534 5.72829 3.80815 5.00493 5.82012H3.77758C4.53951 3.13596 7.01225 1.16402 9.93729 1.16402Z"
        fill={props.color || '#00B8EE'}
      />
    </svg>
  );
};
