import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
  color?: string;
}

export const PersonBlockerIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '29'}
      height={props.height ? props.height : '30'}
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4097 15.1006C18.774 16.6998 16.5343 17.6861 14.0625 17.6861C11.5907 17.6861 9.35105 16.6998 7.71525 15.1006C3.30716 17.3251 0.231508 21.7935 0 27H28.125C27.8935 21.7935 24.8178 17.3251 20.4097 15.1006Z"
        fill={props.color || '#00B8EE'}
      />
      <path
        d="M14.0625 15.2902C17.7431 15.2902 20.7269 12.3151 20.7269 8.6451C20.7269 4.97511 17.7431 2 14.0625 2C10.3819 2 7.3981 4.97511 7.3981 8.6451C7.3981 12.3151 10.3819 15.2902 14.0625 15.2902Z"
        fill={props.color || '#00B8EE'}
      />
    </svg>
  );
};
