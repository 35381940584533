import {
  DirectionalHint,
  FontSizes,
  IPage,
  List,
  ScrollablePane,
  ScrollbarVisibility,
  SearchBox,
  Sticky,
  StickyPositionType,
  TooltipDelay,
  TooltipHost,
  getTheme,
  mergeStyleSets,
} from "@fluentui/react";

import _ from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "../../../store/hooks";
import { SectionTree } from "../SectionTree";

import {
  getExternalPartner,
  getPoliciesList,
  getProjectsListPost,
} from "../../../api/gosApi";

import { useTranslation } from "react-i18next";
import { EBusinessObjectType } from "../../../types/types";
import getTaxonomyIdByType from "../../../utils/utils";
import { ListRow } from "../ListRow";
import { maxNumberOfSearchResults } from "../utils";
import "./SearchList.scss";

interface ISearchListClassObject {
  listWrapper: string;
  listHeader: string;
  listBody: string;
  itemsList: string;
  listHeaderTitle: string;
  listHeaderActionRights: string;
  listHeaderSeparator: string;
}

export type BusinessObjectType =
  | "claims"
  | "policies"
  | "projects"
  | "clients"
  | "mandates"
  | "insurers"
  | "insurerCoops"
  | "intermediaries";

export interface IDestinationLocationInfo {
  containerId: number;
  containerTypeName: string;
  containerName: string;
  partnerTypeName?: string;
  sectionId?: number;
  sectionTypeName?: string;
  virtualFolderId?: number;
  IBOType?: string;
}

export interface ISearchListPorps {
  chosenModule?: BusinessObjectType;
  onSelectDestination: (destination?: IDestinationLocationInfo) => void;
  selectedDestination?: IDestinationLocationInfo;
}

export interface ISection {
  sectionId: number;
  sectionType?: string;
  label: string;
  key: string;
  nodes: ISection[];
  nodesFetched: boolean;
  fetchNodes?: () => Promise<void>;
}

export const SearchList = ({
  chosenModule,
  onSelectDestination,
  selectedDestination,
}: ISearchListPorps) => {
  const { t } = useTranslation();
  const mailConfig = useSelector((s) => s.locations.mailConfiguration);
  const taxonomyGroup = useSelector((s) => s.taxonomy.BusinessObjectType);

  const client = useSelector((s) => s.clients.client);
  const clientId = client?.clientId;

  const clientInternalNumber = client.clientInternalNumber;
  const clientName = client.clientName;
  const clientTypeCode = client.clientType;

  const theme = getTheme();
  const [items, setItems] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(
    clientInternalNumber
  );
  const [sections, setSections] = useState<ISection[]>([]);

  const PAGE_SIZE = 30;

  const searchBarPlaceholder = () => {
    switch (chosenModule) {
      case "policies":
        return t("rvm.searchPolicies.label");
      case "clients":
        return t("rvm.searchClients.label");

      case "projects":
        return t("rvm.searchProjects.label");

      default:
        // TODO Add translations for other modules
        return undefined;
    }
  };

  const searchBarTooltip = () => {
    switch (chosenModule) {
      case "clients":
        return t("");
      case "policies":
        return t("");
      case "projects":
        return t("");
      default:
        // TODO Add translations for other modules
        return undefined;
    }
  };

  const scrollSearchResults = async (skip?: number, searchQuery?: string) => {
    switch (chosenModule) {
      case "projects":
        let { projects, total: totalProjects } = await getProjectsListPost(
          skip || 0,
          {
            field: "projectName",
            dir: "asc",
          },
          searchQuery,
          PAGE_SIZE,
          `clientCompanyID eq ${clientId}`
        );
        if (totalProjects > maxNumberOfSearchResults)
          totalProjects = maxNumberOfSearchResults;
        setItems((oldItems: any[]) => {
          // It is possible that the items were already updated
          if (skip && oldItems[skip]) {
            return [...oldItems];
          }

          const newProjects: any[] = skip
            ? [...oldItems]
            : new Array(totalProjects).fill(null);
          const skipProjectsInd: number = skip || 0;
          for (
            let index = 0;
            index < Math.min(PAGE_SIZE, totalProjects - skipProjectsInd);
            index++
          ) {
            newProjects[index + skipProjectsInd] = projects[index];
          }

          return newProjects;
        });
        break;
      case "policies":
        let { policies, total: totalPolicies } = await getPoliciesList(
          skip || 0,
          {
            field: "clientName",
            dir: "desc",
          },
          searchQuery,
          `clientID eq ${clientId}`,
          PAGE_SIZE
        );
        if (totalPolicies > maxNumberOfSearchResults)
          totalPolicies = maxNumberOfSearchResults;
        setItems((oldItems: any[]) => {
          // It is possible that the items were already updated
          if (skip && oldItems[skip]) {
            return [...oldItems];
          }

          const newPolicies: any[] = skip
            ? [...oldItems]
            : new Array(totalPolicies).fill(null);
          const skipPoliciesInd: number = skip || 0;
          for (
            let index = 0;
            index < Math.min(PAGE_SIZE, totalPolicies - skipPoliciesInd);
            index++
          ) {
            newPolicies[index + skipPoliciesInd] = policies[index];
          }

          return newPolicies;
        });
        break;

      case "clients":
        const clientData = [];
        clientData.push({
          partnerName: clientName,
          partnerNumber: clientInternalNumber,
          partnerID: clientId,
          itemType: "Partner",
          partnerType: [clientTypeCode],
        });

        setItems(clientData);

        break;

      default:
    }
  };

  const search = async (query?: string) => {
    // Clear previous selection

    if (searchQuery !== query) {
      onSelectDestination();
    }
    setSearchQuery(query);
    await scrollSearchResults(undefined, query);
  };

  const classNames: ISearchListClassObject = mergeStyleSets({
    listWrapper: [{ height: "calc(100vh - 186px)" }],
    listHeader: [{ maxWidth: "calc(100vw - 4px)" }],
    listBody: [{ maxWidth: "calc(100vw - 4px)" }],
    itemsList: [{ maxWidth: "calc(100vw - 4px)" }],
    listHeaderTitle: [
      {
        textAlign: "center",
        fontSize: FontSizes.large,
        marginTop: 4,
      },
    ],
    listHeaderActionRights: [{ textAlign: "right" }],
    listHeaderSeparator: [{ padding: 0, height: 24, marginRight: 10 }],
  });

  useEffect(() => {
    setSections([]);
    setItems([]);

    if (chosenModule === "clients") {
      search(clientInternalNumber);
    }
    if (chosenModule === "policies" || chosenModule === "projects") {
      search("");
    }
  }, [chosenModule, clientInternalNumber]);

  const selectDestinationObjectOrFolder = async (
    destination: IDestinationLocationInfo
  ) => {
    if (!_.isEqual(destination, selectedDestination)) {
      if (chosenModule !== "mandates" && chosenModule !== "insurerCoops")
        onSelectDestination(destination);
      setItems([...items]);
      setSections([]);
      if (
        chosenModule === "clients" &&
        destination?.partnerTypeName === "CompanyClient"
      ) {
        // @ts-ignore
        const { data: client } = await getExternalPartner(
          `${destination.containerId}`,

          getTaxonomyIdByType(taxonomyGroup, EBusinessObjectType.CompanyClient),
          {
            includeDepartments: true,
            includePersons: true,
          }
        );
        if (!client) {
          return;
        }
        const root: ISection = {
          sectionId: destination.containerId,
          label: client.partnerName,
          key: `${destination.containerId}`,
          nodes: [],
          nodesFetched: true,
        };
        const sectionNodes: ISection[] = [root];
        if (client.departments) {
          client.departments.forEach((department: any) => {
            const departmentSection: ISection = {
              sectionId: department.partnerId!,
              sectionType: "Department",
              label: department.partnerName!,
              key: `${department.partnerId!}`,
              nodes: [],
              nodesFetched: false,
              fetchNodes: async () => {
                // @ts-ignore
                const { data: contactPersons } = await getExternalPartner(
                  `${department.partnerId}`,

                  getTaxonomyIdByType(
                    taxonomyGroup,
                    EBusinessObjectType.Department
                  ),

                  {
                    includePersons: true,
                  }
                );
                contactPersons.persons.forEach((contactPerson: any) => {
                  departmentSection.nodes.push({
                    sectionId: contactPerson.partnerId!,
                    sectionType: "Contact",
                    label: `${contactPerson.firstName} ${contactPerson.lastName}`,
                    key: `${contactPerson.partnerId!}`,
                    nodes: [],
                    nodesFetched: true,
                  });
                });
                departmentSection.nodesFetched = true;
              },
            };
            root.nodes.push(departmentSection);
          });
        }

        if (client.persons) {
          client.persons.forEach((contactPerson: any) => {
            root.nodes.push({
              sectionId: contactPerson.partnerId!,
              sectionType: "Contact",
              label: `${contactPerson.firstName} ${contactPerson.lastName}`,
              key: `${contactPerson.partnerId!}`,
              nodes: [],
              nodesFetched: true,
            });
          });
        }

        setSections(sectionNodes);
      }
    }
  };

  return (
    <div className={`ms-Grid ${classNames.listWrapper}`} dir="ltr">
      <Sticky
        stickyPosition={StickyPositionType.Header}
        stickyBackgroundColor={theme.palette.white}
      >
        {(chosenModule === "projects" || chosenModule === "policies") && (
          <div
            className={`ms-Grid-row ${classNames.listHeader}`}
            style={{ marginBottom: 10, backgroundColor: theme.palette.white }}
          >
            <TooltipHost
              delay={TooltipDelay.zero}
              directionalHint={DirectionalHint.bottomCenter}
              content={searchBarTooltip()}
            >
              <SearchBox
                placeholder={searchBarPlaceholder()}
                styles={{
                  root: {
                    borderColor: "#e1dfdd",
                    color: theme.palette.neutralPrimary,
                    backgroundColor: theme.palette.neutralLighterAlt,
                    marginLeft: 8,
                    marginRight: 6,
                  },
                  field: {
                    color: theme.palette.neutralPrimary,
                  },
                }}
                onChange={(e) => {
                  setSearchQuery(e?.target?.value || "");
                }}
                onSearch={search}
                value={searchQuery}
              />
            </TooltipHost>
          </div>
        )}
      </Sticky>

      {chosenModule && (
        <>
          <ScrollablePane
            scrollbarVisibility={ScrollbarVisibility.auto}
            styles={{
              root: {
                position: "relative",
                height:
                  chosenModule === "clients" ? "calc(65vh - 186px)" : "inherit",
              },
              contentContainer: {
                overflowX: "hidden",
                position: "relative",
                height:
                  chosenModule === "clients" ? "100%" : "calc(100% - 36px)",
              },
            }}
          >
            <div
              className={`ms-Grid-row ${classNames.listBody}`}
              style={{ marginTop: 20 }}
            >
              <List
                className={classNames.itemsList}
                items={items}
                onRenderCell={(item?: any) => {
                  if (!item) {
                    return null;
                  }
                  let itemName = "";
                  let itemNumber = 0;
                  let itemID = 0;
                  let itemType = "Partner";
                  let partnerTypeName;
                  let IBOType;
                  switch (chosenModule) {
                    case "policies":
                      itemName = item.policyName;
                      itemNumber = item.internalPolicyNumber;
                      itemID = Number(item.policyID);
                      IBOType = item.isKR ? "KRPOL" : undefined;
                      itemType = "Policy";
                      break;
                    case "clients":
                      itemName = item.partnerName;
                      itemNumber = item.partnerNumber;
                      itemID = Number(item.partnerID);
                      itemType = "Partner";
                      partnerTypeName = item.partnerType?.includes(
                        getTaxonomyIdByType(
                          taxonomyGroup,
                          EBusinessObjectType.CompanyClient
                        )
                      )
                        ? "CompanyClient"
                        : "PrivateClient";
                      break;

                    case "projects":
                      itemName = item.projectName;
                      itemNumber = item.internalProjectNumber;
                      itemID = Number(item.projectID);
                      IBOType =
                        item?.projectTypeCodeID == "7429907898"
                          ? "AFFPRJ"
                          : undefined;
                      itemType = "Project";
                      break;

                    default:
                  }

                  return (
                    <ListRow
                      isSelected={
                        mailConfig &&
                        mailConfig?.settingValue?.containerId.toString() ===
                          itemID.toString()
                      }
                      itemName={itemName}
                      itemNumber={itemNumber}
                      itemID={itemID}
                      itemType={itemType}
                      partnerType={partnerTypeName}
                      IBOType={IBOType}
                      isRootSelected={
                        !!selectedDestination &&
                        selectedDestination.containerId === itemID &&
                        !selectedDestination.virtualFolderId
                      }
                      selectedVirtualFolderId={
                        (selectedDestination &&
                          selectedDestination.virtualFolderId) ||
                        mailConfig?.settingValue?.virtualFolderId
                      }
                      onSelectDestination={selectDestinationObjectOrFolder}
                    />
                  );
                }}
                getItemCountForPage={() => PAGE_SIZE}
                getPageHeight={() => 36 * PAGE_SIZE}
                renderedWindowsAhead={0}
                renderedWindowsBehind={0}
                ignoreScrollingState
                onPagesUpdated={(pages: IPage<any>[]) => {
                  pages.forEach((page: IPage<any>) => {
                    if (
                      page.startIndex &&
                      items[page.startIndex] === null &&
                      !page.isSpacer
                    ) {
                      scrollSearchResults(page.startIndex, searchQuery);
                    }
                  });
                }}
              />
            </div>
          </ScrollablePane>

          {chosenModule === "clients" && (
            <SectionTree
              data={sections}
              chosenModule={chosenModule}
              updateTree={() => setSections([...sections])}
              selectedDestination={selectedDestination}
              onSelectDestination={onSelectDestination}
            />
          )}
        </>
      )}
    </div>
  );
};
