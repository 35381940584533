import { Spinner } from "@fluentui/react";
import styled from "styled-components";
import { zIndex } from "../config/zIndex";

export const PageSpinner = () => {
  return (
    <PageSpinnerStyled
      styles={{
        circle: {
          width: 50,
          height: 50,
          borderWidth: 4,
        },
      }}
    />
  );
};

const PageSpinnerStyled = styled(Spinner)`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${zIndex.pageSpinner};
`;
