import React, { useRef } from "react";
import { createPortal } from "react-dom";
import ReactTooltip from "react-tooltip";

export const Tooltip = () => {
  const refTooltip = useRef(null);

  return createPortal(
    <ReactTooltip
      id="tooltip"
      effect="solid"
      ref={refTooltip}
      afterShow={(e) => {
        // const { target } = e;
        const { tooltipRef } = refTooltip.current as any;

        if (!tooltipRef) return;

        // const targetRect = target.getBoundingClientRect();
        const rect = tooltipRef.getBoundingClientRect();

        const overflownLeft = rect.left < 0;
        const overflownRight = rect.right > window.innerWidth;

        if (overflownLeft) {
          tooltipRef.style.setProperty("left", "10px");
          tooltipRef.style.setProperty("right", "auto");
        } else if (overflownRight) {
          tooltipRef.style.setProperty("left", "auto");
          tooltipRef.style.setProperty("right", "10px");
        }
      }}
    />,
    document.body
  );
};
