import React, { useEffect, useState } from "react";

export interface ContextProps {
  children: React.ReactNode;
}

interface IContextValueTypes {
  state: IState;
  setupSidebar: (id: number, type: any, url: string) => void;
  setUrl: any;
  setMandateType: any;
  setDmsTopObjectId: any;
  setPType: (departmentPType: string) => void;
  setProjectData: (projectData: {
    clientId?: number;
    clientType?: "corporate" | "private" | "";
    projectTypeCode?: string;
  }) => void;
}

interface IState {
  url: string;
  typeId: any;
  id: number;
  mandateType: string;
  mandateData: undefined;
  dmsTopObjectId: number;
  PType: string;
  projectData: {
    clientId?: number;
    clientType?: "corporate" | "private" | "";
    projectTypeCode?: string;
  };
}

const CrmSideBarContext = React.createContext({} as IContextValueTypes);
const CrmSideBarConsumer = CrmSideBarContext.Consumer;

const CrmSideBarContextProvider = ({ children }: ContextProps) => {
  const [url, setUrl] = useState<string>("");
  const [typeId, setTypeId] = useState<any>(0);
  const [id, setId] = useState<number>(0);
  const [mandateType, setMandateType] = useState<"corporate" | "private" | "">(
    ""
  );
  // const [mandateData, setMandateData] = useState<MandateData | undefined>(
  //   undefined
  // );
  const [dmsTopObjectId, setDmsTopObjectId] = useState<number>(0);
  const [PType, setPType] = useState<any>("");
  const [projectData, setProjectData] = useState<{
    clientId?: number;
    clientType?: "corporate" | "private" | "";
    projectTypeCode?: string;
  }>({});
  // const lastLocation = useLastLocation();
  const gosUrl = process.env.REACT_APP_GOS_BASE_URL;

  const sidebarRoutes = [
    { route: "/crm/client/corporate/" },
    { route: "/crm/client/private/" },
    { route: "/crm/client/person/" },
    { route: "/crm/mandate/corporate/readonly" },
    { route: "/crm/mandate/private/readonly" },
    { route: "/crm/client/subclients/" },
    { route: "/crm/mandate/list/" },
    { route: "/crm/project/readonly/" },
    { route: "ctype=partner&ptype=companyclient" },
    { route: "ctype=partner&ptype=privateclient" },
    { route: "ctype=partner&stype=contact" },
    { route: "ctype=partner&ptype=intermediary" },
    { route: "ctype=partner&ptype=insurer" },
    { route: "ctype=mandate" },
    { route: "organisationoid" },
    { route: "partneroid" },
    { route: "type=mandate" },
    { route: "type=corporateclient" },
    { route: "type=privateclient" },
    { route: "type=contact" },
    { route: "type=intermediary" },
    { route: "type=branchoffice" },
    { route: "/crm/intermediary/" },
    { route: "type=insurer" },
    { route: "/crm/insurer/" },
    { route: "/crm/branch-office/readonly" },
    { route: "/crm/branch-office/departments" },
    { route: "/crm/cooperation-agreement/readonly" },
    { route: "type=cooperationagreement" },
    { route: "ctype=coop" },
    { route: "ctype=project" },
    { route: "type=project" },
    { route: "/crm/department/readonly/" },
    { route: "type=department" },
  ];

  const alwaysExcludeRoutes = [
    { route: "/claim", exclude: "/claims" },
    { route: "/policy" },
  ];

  const setupSidebar = (id: number, type: any, url: string) => {
    setId(id);
    setTypeId(type);
    setUrl(url);
  };

  useEffect(() => {
    const pathname = window.location.pathname.toLowerCase();
    const search = window.location.search.toLowerCase();

    const shouldShowSideBar: number = sidebarRoutes.filter(
      (item: { route: string }) =>
        pathname.includes(item.route) ||
        (search.includes(item.route) &&
          !alwaysExcludeRoutes.find(
            (routeToExclude: { route: string; exclude?: string }) =>
              pathname.includes(routeToExclude.route) &&
              (!routeToExclude.exclude ||
                !pathname.includes(routeToExclude.exclude))
          ))
    ).length;

    !shouldShowSideBar && setupSidebar(0, "", "");
  }, [window.location]); //lastLocation

  useEffect(() => {
    const pathname = window.location.pathname.toLowerCase();
    const search = window.location.search.toLowerCase();
    const exclude = [
      "crm/branch-office/readonly",
      "type=branchoffice",
      "crm/branch-office/departments",
      "stype=branch",
    ];
    if (dmsTopObjectId) {
      const shouldExcludeCount = exclude.filter((item: string) => {
        return pathname.includes(item) || search.includes(item);
      });
      !Boolean(shouldExcludeCount.length) && setDmsTopObjectId(0);
    }
  }, [window.location]); //lastLocation

  return (
    <CrmSideBarContext.Provider
      value={{
        state: {
          url,
          typeId,
          id,
          mandateType,
          mandateData: undefined,
          dmsTopObjectId,
          PType,
          projectData,
        },
        setupSidebar,
        setUrl,
        setMandateType,
        setDmsTopObjectId,
        setPType,
        setProjectData,
      }}
    >
      {children}
    </CrmSideBarContext.Provider>
  );
};

export {
  CrmSideBarConsumer,
  CrmSideBarContext,
  CrmSideBarContextProvider as default,
};
