import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogType,
  mergeStyleSets,
} from "@fluentui/react";
import React from "react";

import { useTheme } from "@emotion/react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Flex } from "../../../components/styled";
import i18n from "../../../i18n";
import { ICampaignInfo } from "../../../types/types";
import LogPersona from "../../valueMonitoring/history/LogPersona";
import ConfirmActionFormDialog from "./ConfirmActionFormDialog";

type Props = {
  showDialog: boolean;
  toggleShowDialog: () => void;
  getCampaignData: () => void;
  campaignInfo: ICampaignInfo;
  title: string;
  campaignHistory: ICampaignInfo[];
};
function CampaignDialog({
  toggleShowDialog,
  showDialog,
  campaignInfo,
  campaignHistory,
  title,
  getCampaignData,
}: Props) {
  const { t } = useTranslation();

  const theme = useTheme();

  const classes = getClasses(theme);

  return (
    <>
      <Dialog
        hidden={!showDialog}
        onDismiss={toggleShowDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: <div>{title}</div>,
        }}
        minWidth={700}
        modalProps={{
          isBlocking: true, //phraseActivated ? false :
        }}
      >
        <div className={classes.wrap}>
          <div className={classes.content}>
            <DialogContent>
              <h3 className={classes.historyTitle}>
                {t("rvm.campaignStatusHistory.label")}
              </h3>
              <div className={classes.divider}></div>
              <div>
                {campaignHistory?.map((item) => {
                  const { setAt, comment, setByUserName, isCampaignClosed } =
                    item;

                  const statusText = isCampaignClosed
                    ? t("rvm.campaignIsClosed.label")
                    : t("rvm.campaignIsOpen.label");
                  const createdAt = moment(setAt)
                    .locale(i18n.language)
                    .format("DD. MMMM YYYY");
                  return (
                    <div key={`${setAt}`}>
                      <Flex
                        justifyContent="space-between"
                        alignItems="baseline"
                      >
                        <LogPersona name={`${setByUserName} - ${createdAt}`} />
                        <div>{statusText}</div>
                      </Flex>
                      <div className={classes.comment}>{comment}</div>
                      <div className={classes.divider}></div>
                    </div>
                  );
                })}
              </div>
            </DialogContent>
          </div>
          <DialogFooter>
            <ConfirmActionFormDialog
              actionType={title}
              getCampaignData={getCampaignData}
              campaignInfo={campaignInfo}
              toggleCampaignDialog={toggleShowDialog}
            />
          </DialogFooter>
        </div>
      </Dialog>
    </>
  );
}

export default CampaignDialog;

const getClasses = (theme) =>
  mergeStyleSets({
    wrap: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    content: {
      maxHeight: "60vh",
      overflowY: "scroll",
    },
    historyTitle: {
      marginBottom: 20,
    },

    comment: {
      display: "flex",
      width: "100%",
    },

    divider: {
      border: `1px solid ${theme?.palette?.neutralLighter}`,
      height: "auto",
      width: "100%",
      margin: "20px 0 10px",
    },
  });
