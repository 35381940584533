import { useFormikContext } from "formik";
import { useEffect } from "react";
import { stringToNumber } from "../../../utils/utils";

export const useTotalPdBiBasedOnIndemnityPeriod = () => {
  const formik = useFormikContext();
  const totalPd =
    formik.values["totalPd"] && formik.values["totalPd"] !== ""
      ? stringToNumber(formik.values["totalPd"])
      : 0;
  const businessInteruptionValuesBasedOnIndemnityPeriod =
    formik.values["businessInteruptionValuesBasedOnIndemnityPeriod"] &&
    formik.values["businessInteruptionValuesBasedOnIndemnityPeriod"] !== ""
      ? stringToNumber(
          formik.values["businessInteruptionValuesBasedOnIndemnityPeriod"]
        )
      : 0;

  useEffect(() => {
    const totalPdBiValue =
      totalPd + businessInteruptionValuesBasedOnIndemnityPeriod;
    const totalPdBiString = totalPdBiValue.toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    if (formik.dirty && !formik.isSubmitting) {
      formik.setFieldValue("totalPdBiBasedOnIndemnityPeriod", totalPdBiString);
    }
  }, [
    totalPd,
    formik.touched["totalPd"],
    formik.values["totalPd"],
    businessInteruptionValuesBasedOnIndemnityPeriod,
    formik.touched["businessInteruptionValuesBasedOnIndemnityPeriod"],
    formik.values["businessInteruptionValuesBasedOnIndemnityPeriod"],
  ]);
};
