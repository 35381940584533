import { css } from "@emotion/css";
import { Label } from "@fluentui/react";
import { useField } from "formik";
import { VSpace } from "../Spacer";
import { ErrorMessage, StyledTextField } from "../styled";
import { useNumberFormat } from "../use-number-format/useNumberFormat";
import { NumberFieldConfig } from "./types";
import { useDynamicConfig } from "./useDynamicConfig";

type Props = { fieldConfig: NumberFieldConfig };

export const NumberField = ({ fieldConfig }: Props) => {
  const config = {
    ...fieldConfig,
    ...useDynamicConfig(fieldConfig.d),
  };
  const {
    name,
    label,
    isVisible = true,
    isDisabled,
    format,
    isRequired,
    includeThousandSeparators,
    max,
    isNegativeAllowed,
    validate,
    background,
  } = config;

  const [field, { error }, { setValue }] = useField({ name, validate });
  const rifm = useNumberFormat({
    value: field.value,
    setValue,
    format,
    includeThousandSeparators,
    max,
    isNegativeAllowed,
  });

  if (!isVisible) return null;

  return (
    <div
      className={css`
        flex: 1;
      `}
    >
      <Label required={isRequired} disabled={isDisabled}>
        {label}
      </Label>
      <StyledTextField
        id={name}
        name={name}
        borderless
        disabled={isDisabled}
        hasError={!!error}
        background={background}
        {...field}
        {...(rifm as any)}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <VSpace height={10} />
    </div>
  );
};
