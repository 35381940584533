import { useTheme } from "@emotion/react";
import { ActionButton, mergeStyleSets } from "@fluentui/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "../../../../../components/FluentToast";
import { GeneralIcon } from "../../../../../components/GeneralIcon";
import { HistoryIcon } from "../../../../../components/HistoryIcon";
import { TitleWithSection } from "../../../../../components/TitleWithSection/TitleWithSection";
import { VerticalSplitContainer } from "../../../../../components/VerticalSplitContainer/VerticalSplitContainer";
import { useCurrentRoute } from "../../../../../config/routes";
import useIsExternalUser from "../../../../../hooks/useIsExternalUser";
import useIsInternalUser from "../../../../../hooks/useIsInternalUser";
import { statusColors } from "../../../../../library/constants";
import { useSelector } from "../../../../../store/hooks";
import { setFormObject } from "../../../../../store/valueMonitoring/valueMonitoringPage";
import FormSkeleton from "../../../form/FormSkeleton";
import PropertyInfoForm from "../../../form/PropertyInfoForm";
import { getEnteredValuesChangesConfig } from "../../../form/constants";
import HistoryTable from "../../../history/HistoryTable/HistoryTable";
import HistoryUsers from "../../../history/HistoryUsers";
import { CurrentPropertyInfo } from "../../../readOnly/CurrentPropertyInfo";
import ResetForm from "../../ResetForm";
import ValueMonitoringAnchorNav from "../../ValueMonitoringAnchorNav";

type Props = {
  file: File | null;
  setFile: (file: File) => void;
  filename: string;
  setFilename: (value: string) => void;
  formProps: any;
  handleUpdateSchema: (schame: any) => void;
};

function MainContent({
  file,
  setFile,
  filename,
  setFilename,
  formProps,
  handleUpdateSchema,
}: Props) {
  const { t } = useTranslation();
  const equalMessage = getEnteredValuesChangesConfig(t).equalMessage;

  const [resetFormState, setResetFormState] = useState(false);
  const taxonomy = useSelector((s) => s.taxonomy);
  const userRole = useSelector((s) => s.userRole.userRole);
  const route = useCurrentRoute();
  const theme = useTheme();
  const formObject = useSelector((s) => s.valueMonitoring.formObject);
  const dataLoadStatus = useSelector((s) => s.valueMonitoring.dataLoadStatus);
  const historyData = useSelector((s) => s.valueMonitoring.historyData);

  const dispatch = useDispatch();

  const selectedLocation = useSelector(
    (s) => s.valueMonitoring.selectedLocation
  );

  const isExternalUser = useIsExternalUser();

  const isInternalUser = useIsInternalUser();

  const isInvitationSent = formObject
    ? taxonomy.PropertyInformationStatus.byId[formObject.statusCode].code ===
      "PropertyInformationStatus.INVITATION_SENT"
    : false;
  const isEditMode = useMemo(() => {
    return route === "valueMonitoringForLocationEdit";
  }, [route]);

  const isDisabledLocation =
    taxonomy.PropertyInformationStatus.byId[
      selectedLocation?.location?.statusCode
    ].code === "PropertyInformationStatus.DISABLED";

  const dontShowForm =
    (isInternalUser && !isEditMode) || (isExternalUser && !isInvitationSent);

  const isReadOnly =
    isDisabledLocation ||
    (!isInvitationSent && isExternalUser) ||
    (isInvitationSent && isInternalUser);

  let status;

  if (selectedLocation) {
    status =
      statusColors[
        taxonomy.PropertyInformationStatus.byId[
          selectedLocation.location.statusCode
        ].code
      ].color;
  } else {
    status = "#ffffff";
  }

  const locationFieldsDisabled: boolean = useMemo(() => {
    if (!formObject || typeof formObject === "undefined") return false;
    if (
      isInternalUser &&
      taxonomy?.PropertyInformationStatus.byId[formObject.statusCode].code !==
        "PropertyInformationStatus.REVIEW"
    ) {
      return true;
    } else {
      return false;
    }
  }, [formObject, taxonomy, isInternalUser]);

  const classes = getClassNames();
  return (
    <VerticalSplitContainer
      isReady={taxonomy !== null}
      containerWidth="80%"
      leftAreaWidth={dontShowForm ? "0%" : "20%"}
      rightAreaWidth={dontShowForm ? "100%" : "80%"}
      leftAreaRender={ValueMonitoringAnchorNav}
      borderTopColor={status}
      rightAreaRender={() => {
        return (
          <div className={`${classes.contentWrap} commonReadOnly`}>
            <TitleWithSection
              icon={<GeneralIcon width="35" height="35" />}
              title={
                isEditMode ? t("rvm.general.label") : t("rvm.current.label")
              }
              styles={{
                wrap: { paddingLeft: 20 },
                title: {
                  fontSize: "1.05rem",
                },
                icon: {
                  height: 35,
                  paddingRight: 10,
                },
                content: {
                  padding: "10px 0 5px 45px",
                  color: theme.palette.black,
                },
              }}
              content={() => {
                if (formObject !== null && selectedLocation !== null) {
                  if (dataLoadStatus !== "loading") {
                    if (isReadOnly && isEditMode) return null;
                    if (
                      (isInternalUser && !isEditMode) ||
                      (isExternalUser && !isInvitationSent) ||
                      isDisabledLocation
                    ) {
                      return <CurrentPropertyInfo />;
                    } else {
                      return (
                        <div className={`rvm-form-Current`}>
                          <PropertyInfoForm
                            formResetState={resetFormState}
                            filename={filename}
                            file={file}
                            setFile={setFile}
                            setFilename={setFilename}
                            formikProps={formProps}
                            handleUpdateSchema={handleUpdateSchema}
                          />
                          <div className={classes.rightButtons}>
                            {historyData && historyData.length > 1 ? (
                              userRole === "external" ? (
                                <ActionButton
                                  iconProps={{
                                    iconName: "Copy",
                                  }}
                                  //className={errorHandlerClasses.copyToIcon}
                                  onClick={() => {
                                    const lastPeriod = historyData[1];
                                    toast.info(equalMessage);
                                    setResetFormState((prev) => !prev);

                                    dispatch(
                                      setFormObject({
                                        ...formObject,
                                        buildingValues:
                                          lastPeriod.buildingValues,
                                        equipmentValues:
                                          lastPeriod.equipmentValues,
                                        stockValues: lastPeriod.stockValues,
                                        totalPd: lastPeriod.totalPd,
                                        businessInteruptionValues12Months:
                                          lastPeriod.businessInteruptionValues12Months,
                                        totalPdBi: lastPeriod.totalPdBi,
                                        indemnityPeriodCode:
                                          lastPeriod.indemnityPeriodCode,
                                        businessInteruptionValuesBasedOnIndemnityPeriod:
                                          lastPeriod.businessInteruptionValuesBasedOnIndemnityPeriod,
                                        totalPdBiBasedOnIndemnityPeriod:
                                          lastPeriod.totalPdBiBasedOnIndemnityPeriod,
                                        clientComment: lastPeriod.clientComment,
                                        desiredLocationOwningStatusCode:
                                          lastPeriod.desiredLocationOwningStatusCode,
                                        desiredLocationOwningStatusDate:
                                          lastPeriod.desiredLocationOwningStatusDate,
                                        desiredLocationStatusCode:
                                          lastPeriod.desiredLocationStatusCode,
                                        desiredLocationStatusDate:
                                          lastPeriod.desiredLocationStatusDate,
                                        statusDate: lastPeriod.statusDate,
                                        buildingValuesComment:
                                          lastPeriod.buildingValuesComment,

                                        equipmentValuesComment:
                                          lastPeriod.equipmentValuesComment,

                                        businessInteruptionValues12MonthsComment:
                                          lastPeriod.businessInteruptionValues12MonthsComment,

                                        desiredLocationStatusCodeComment:
                                          lastPeriod.desiredLocationStatusCodeComment,
                                      })
                                    );
                                  }}
                                >
                                  {t("rvm.copyFromLastPeriod.label")}
                                </ActionButton>
                              ) : null
                            ) : null}
                            <ResetForm
                              resetStates={() => {
                                setFile(null);
                                setResetFormState((prev) => !prev);
                              }}
                              setFieldValue={formProps.setFieldValue}
                              locationFieldsDisabled={locationFieldsDisabled}
                              formObject={formObject}
                            />
                          </div>
                        </div>
                      );
                    }
                  } else {
                    return <FormSkeleton />;
                  }
                }
                return <FormSkeleton />;
              }}
            />
            {!isEditMode ? (
              <div className={`rvm-form-History`}>
                <TitleWithSection
                  icon={<HistoryIcon width="35" height="35" />}
                  title={t("History")}
                  styles={{
                    wrap: { paddingLeft: 20 },
                    title: {
                      fontSize: "1.05rem",
                    },
                    icon: {
                      height: 35,
                      paddingRight: 10,
                    },
                    content: {
                      padding: "10px 0 45px 45px",
                      color: theme.palette.black,
                    },
                  }}
                  content={() => {
                    return (
                      <>
                        <HistoryTable />
                        <HistoryUsers />
                      </>
                    );
                  }}
                />
              </div>
            ) : null}
          </div>
        );
      }}
      leftAreaShimmer={() => {
        return null; //<AnchorNavShimmer numberOfButtons={2} />
      }}
      rightAreaShimmer={() => {
        return null; //<CorporateFormShimmer />;
      }}
    />
  );
}

export default MainContent;

const getClassNames = () =>
  mergeStyleSets({
    rightButtons: {
      textAlign: "right",
    },
    contentWrap: {
      marginTop: "20px",
    },
  });
