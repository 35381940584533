import React from 'react';
interface iconProps {
  height?: string;
  width?: string;
  color?: string;
}
export const PremiumClientIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '31'}
      height={props.height ? props.height : '31'}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.0567 18.9547L22.181 9.59045V6.24284L24.0562 0H18.8055C17.6803 0 16.6958 0.45238 15.9926 1.22143C15.2894 0.45238 14.3048 0 13.1797 0H7.92892L9.80419 6.24284V9.59045L4.92849 18.9547C3.56892 21.5333 3.70957 24.5642 5.30355 27.0523C6.89752 29.5404 9.61666 30.988 12.6171 30.988H19.4149C22.4154 30.988 25.1814 29.4952 26.7285 27.0523C28.2756 24.5642 28.4162 21.5333 27.0567 18.9547Z"
        fill={props.color || '#00B8EE'}
      />
      <rect
        x="9.33398"
        y="6.73633"
        width="13.3352"
        height="2.69456"
        fill={props.color || '#00A9DB'}
      />
      <path
        d="M18.0394 24.0836C16.3163 24.0836 14.8121 23.1669 14.0036 21.8101H18.0576V20.2944H13.4423C13.3995 20.0473 13.3721 19.7952 13.3721 19.5365C13.3721 19.2778 13.3995 19.0257 13.4423 18.7786H18.0576V17.2629H14.0036C14.8121 15.906 16.3163 14.9893 18.0394 14.9893C18.6107 14.9893 19.1766 15.0937 19.7213 15.2994L20.0024 13.8859C19.2777 13.6124 18.8036 13.4736 18.0394 13.4736C15.4335 13.4736 13.2 15.0439 12.2745 17.2629H10.6677V18.7787H11.8704C11.8383 19.0275 11.8163 19.2793 11.8163 19.5365C11.8163 19.7937 11.8383 20.0455 11.8704 20.2944H10.6677V21.8101H12.2745C13.2 24.0291 15.4335 25.5994 18.0394 25.5994C18.8037 25.5994 19.2777 25.4606 20.0024 25.1872L19.7213 23.7736C19.1766 23.9793 18.6107 24.0837 18.0394 24.0836Z"
        fill={props.color ? 'white' : '#92E6FF'}
      />
    </svg>
  );
};
