import { Dialog, DialogContent, DialogType } from "@fluentui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getConfigurationSettings } from "../../../api/settingConfigurationApi";
import { toast } from "../../../components/FluentToast";
import { useSelector } from "../../../store/hooks";
import { EConfigurationSetting } from "../../../types/types";
import DocumentList from "./DocumentList";

type Props = {
  showDialog;
  toggleShowDialog;
};
function ConfigDocDialog({ toggleShowDialog, showDialog }: Props) {
  const { t } = useTranslation();
  const clientId = useSelector((s) => s.clients.clientId);
  const [dataLoading, setDataLoading] = useState(false);

  const [configurationSettings, setConfigurationSettings] = useState(null);

  const fetchDocumentConfigurationSettings = async (clientId) => {
    try {
      setDataLoading(true);
      const response = await getConfigurationSettings({
        clientId,
        settingName: EConfigurationSetting.Document,
      });

      const data = response
        ? {
            ...response,
            settingValue: JSON.parse(response.settingValue),
          }
        : null;
      setConfigurationSettings(data);
      setDataLoading(false);
    } catch (error: any) {
      toast.error(error.message);
      setDataLoading(false);
    }
  };

  const handleSetDataLoading = useCallback((state: boolean) => {
    setDataLoading(state);
  }, []);

  useEffect(() => {
    if (!clientId) return;
    fetchDocumentConfigurationSettings(clientId);
  }, [clientId]);

  const handleSetConfigurationSettings = useCallback((data) => {
    setConfigurationSettings((prev) => {
      return {
        ...prev,
        ...data,
      };
    });
  }, []);

  return (
    <>
      <Dialog
        hidden={!showDialog}
        onDismiss={() => {
          toggleShowDialog();
          fetchDocumentConfigurationSettings(clientId);
        }}
        dialogContentProps={{
          type: DialogType.normal,
          title: <div>{t("rvm.configDocuments.label")}</div>,
        }}
        minWidth={700}
        modalProps={{
          isBlocking: true, //phraseActivated ? false :
        }}
      >
        <DialogContent>
          <DocumentList
            handleSetDataLoading={handleSetDataLoading}
            dataLoading={dataLoading}
            configurationSettings={configurationSettings}
            handleSetConfigurationSettings={handleSetConfigurationSettings}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ConfigDocDialog;
