import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
}

export const FleetIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '30'}
      height={props.height ? props.height : '24'}
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.7157 18.4932V6.76225C29.7157 5.88789 29.0091 5.17383 28.1276 5.17383H25.9785V20.242H27.96C28.9362 20.242 29.7157 19.4623 29.7157 18.4932Z"
        fill="#007298"
      />
      <path
        d="M26.0659 1.58842C26.0659 0.714061 25.3592 0 24.4778 0H5.88645C5.01225 0 4.29834 0.706774 4.29834 1.58842V18.6822C4.29834 19.5565 5.00497 20.2706 5.88645 20.2706H28.164C27.0057 20.2633 26.0659 19.3161 26.0659 18.1503V1.58842Z"
        fill="url(#paint0_linear)"
      />
      <path
        opacity="0.66"
        d="M22.2113 7.06862C22.1458 7.06862 22.0802 7.0759 22.0147 7.09776L21.257 7.30906L20.4702 5.38547C20.2444 4.839 19.5888 4.39453 18.9987 4.39453H11.6117C11.0216 4.39453 10.3587 4.839 10.1402 5.38547L9.35338 7.30906L8.60304 7.10505C8.53748 7.08319 8.4719 7.0759 8.40633 7.0759C8.04937 7.0759 7.78711 7.35278 7.78711 7.73167V8.18342C7.78711 8.62789 8.14407 8.98492 8.58846 8.98492H8.67589L8.55203 9.29823C8.34077 9.80099 8.17323 10.6608 8.17323 11.2073V15.0253C8.17323 15.4698 8.5302 15.8268 8.97458 15.8268H10.0236C10.468 15.8268 10.825 15.4698 10.825 15.0253V14.0635H19.8219V15.018C19.8219 15.4625 20.1789 15.8195 20.6232 15.8195H21.6723C22.1167 15.8195 22.4736 15.4625 22.4736 15.018V11.2C22.4736 10.6535 22.306 9.7937 22.0948 9.29095L21.971 8.97763H22.0657C22.51 8.97763 22.867 8.6206 22.867 8.17614V7.72439C22.8306 7.3455 22.5683 7.06862 22.2113 7.06862ZM9.90704 8.54045L10.9707 5.94652C11.1455 5.82994 11.2766 5.7425 11.4515 5.62592H19.1225C19.2974 5.7425 19.4285 5.82994 19.6033 5.94652L20.667 8.54045C20.7981 8.86834 20.6232 9.13065 20.2663 9.13065H10.2931C9.95074 9.13065 9.77591 8.86105 9.90704 8.54045ZM12.5733 12.2201C12.5733 12.3949 12.4277 12.5407 12.2528 12.5407H9.98718C9.81235 12.5407 9.66665 12.3949 9.66665 12.2201V11.1344C9.66665 10.9595 9.81235 10.8138 9.98718 10.8138H12.2528C12.4277 10.8138 12.5733 10.9595 12.5733 11.1344V12.2201ZM20.8928 12.2201C20.8928 12.3949 20.7471 12.5407 20.5722 12.5407H18.3066C18.1318 12.5407 17.9861 12.3949 17.9861 12.2201V11.1344C17.9861 10.9595 18.1318 10.8138 18.3066 10.8138H20.5722C20.7471 10.8138 20.8928 10.9595 20.8928 11.1344V12.2201Z"
        fill="white"
      />
      <path
        d="M5.84256 20.3205C4.99022 20.3205 4.30542 19.6356 4.30542 18.7831V12.3711C1.82125 13.0706 0 15.3585 0 18.0763C0 21.3479 2.65171 24.0001 5.92266 24.0001C8.39955 24.0001 10.5195 22.4772 11.4083 20.3205H7.93332H5.84256Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M11.0369 15.096C10.0098 13.34 8.1011 12.1523 5.91562 12.1523C5.35467 12.1523 4.81557 12.2325 4.29834 12.3782V15.096V18.7902C4.29834 19.6354 4.98313 20.3276 5.83547 20.3276H11.4012C11.6853 19.6354 11.8383 18.8776 11.8383 18.0834C11.8456 16.9905 11.5542 15.9704 11.0369 15.096Z"
        fill="#007298"
      />
      <path
        d="M5.78412 15.1914L3.2417 16.3208V18.0185C3.2417 19.4903 4.19601 20.8747 5.52186 21.3338L5.89339 15.2424L5.78412 15.1914Z"
        fill="#FAFFFF"
      />
      <path
        d="M5.89449 15.2344L5.52295 21.3257C5.61037 21.3549 5.69779 21.384 5.78521 21.4059C7.2422 21.0489 8.32765 19.5843 8.32765 18.0178V16.32L5.89449 15.2344Z"
        fill="#8CF2F2"
      />
      <path
        d="M5.79196 21.5755L5.74824 21.5682C4.2184 21.1966 3.06738 19.6592 3.06738 17.9979V16.1763L5.79196 14.9668L8.51653 16.1763V17.9906C8.51653 19.6519 7.36552 21.182 5.83568 21.5609L5.79196 21.5755ZM3.42434 16.4168V17.9906C3.42434 19.4697 4.43695 20.8396 5.78467 21.1966C7.13239 20.8396 8.14499 19.477 8.14499 17.9906V16.4168L5.78467 15.3675L3.42434 16.4168Z"
        fill="#1BB3CC"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="16.9495"
          y1="26.1474"
          x2="13.9453"
          y2="-1.82481"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00B2D4" />
          <stop offset="0.6569" stopColor="#00CAE7" />
          <stop offset="1" stopColor="#00D8F3" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="-7.01332"
          y1="22.2685"
          x2="25.0972"
          y2="11.8728"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00B2D4" />
          <stop offset="0.6569" stopColor="#00CAE7" />
          <stop offset="1" stopColor="#00D8F3" />
        </linearGradient>
      </defs>
    </svg>
  );
};
