import {
  DefaultButton,
  Dialog,
  DialogContent,
  DialogType,
  PrimaryButton,
} from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Flex } from "../../../components/styled";

type Props = {
  handleReset: () => void;
  disabled: boolean;
};

function ClearDocConfig({ handleReset, disabled }: Props) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = useState(false);

  const toggleDialog = () => {
    setOpenDialog((prev) => !prev);
  };

  return (
    <>
      <DefaultButton
        disabled={disabled}
        text={t("rvm.resetConfig.label")}
        onClick={toggleDialog}
        allowDisabledFocus={true}
      />
      <Dialog
        hidden={!openDialog}
        onDismiss={toggleDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: <div>{t("rvm.resetConfig.label")}</div>,

          showCloseButton: true,
        }}
        minWidth={400}
        modalProps={{
          isBlocking: true, //phraseActivated ? false :
        }}
      >
        <DialogContent>
          <Flex justifyContent="center" gap={15}>
            <PrimaryButton
              text={t("greco.yes")}
              onClick={handleReset}
              allowDisabledFocus={true}
            />
            <DefaultButton
              text={t("greco.no")}
              onClick={toggleDialog}
              allowDisabledFocus
              disabled={disabled}
            />
          </Flex>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ClearDocConfig;
