import { FontSizes, getTheme, mergeStyleSets } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { BranchOfficeIcon } from "../../components/componentIcons/BranchOfficeIcon";
import { CorporateClientIcon } from "../../components/componentIcons/CorporateClientIcon";
import { InsurerIcon } from "../../components/componentIcons/InsurerIcon";
import { IntermediaryIcon } from "../../components/componentIcons/IntermediaryIcon";
import { PersonBlockerIcon } from "../../components/componentIcons/PersonBlockerIcon";
import { BasicTextWithIcon } from "../TextWithIcon/BasicTextWithIcon/BasicTextWithIcon";
import { ClientBasicTableInfoShimmer } from "./ClientBasicTableInfoShimmer";

interface Props {
  clientType?:
    | "private"
    | "corporate"
    | "intermediary"
    | "insurer"
    | "branchoffice";
  clientName?: string | null;
  clientPartnerNumber?: number | null;
  clientPartnerId?: number | null;
  style?: {
    container?: React.CSSProperties;
    partnerArea?: React.CSSProperties;
    numberArea?: React.CSSProperties;
  };
  loading: boolean;
  hidePartnerNumberLabel?: boolean;
}

const ClientBasicTableInfo = ({
  clientType,
  clientName,
  clientPartnerNumber,
  clientPartnerId,
  style,
  loading,
  hidePartnerNumberLabel = false,
}: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = getTheme();

  const generalStyles = mergeStyleSets({
    container: {
      padding: "20px 20px 10px 30px",
      fontSize: FontSizes.medium,
      color: theme.palette.black,
    },
    keyText: {
      fontWeight: "600",
      marginRight: "6px",
    },
    keyValueWrap: {
      display: "flex",
      alignItems: "flex-start",
    },
    valueTextWithIcon: {
      fontWeight: "600",
      display: "flex",
      alignItems: "flex-start",
      "span:nth-child(2)": {
        marginLeft: 5,
      },
    },
    link: {
      selectors: {
        "&:hover": {
          textDecoration: "underline",
          cursor: "pointer",
        },
      },
    },
  });

  if (loading) {
    return <ClientBasicTableInfoShimmer />;
  }

  return (
    <div className={generalStyles.container} style={style?.container}>
      <div className={generalStyles.keyValueWrap} style={style?.partnerArea}>
        <BasicTextWithIcon
          text={clientName}
          textWithIconClassName={`${generalStyles.valueTextWithIcon}`}
          iconStyle={{ display: "flex", paddingTop: "2px" }}
          onRenderIcon={() => {
            if (clientType === "private") {
              return <PersonBlockerIcon width="15" height="15" />;
            }
            if (clientType === "corporate") {
              return <CorporateClientIcon width="15" height="15" />;
            }
            if (clientType === "intermediary") {
              return <IntermediaryIcon width="15" height="15" />;
            }
            if (clientType === "insurer") {
              return <InsurerIcon width="15" height="15" />;
            }
            if (clientType === "branchoffice") {
              return <BranchOfficeIcon width="15" height="15" />;
            }
            return null;
          }}
          onTextClick={() => {
            // clientPartnerId && onPartnerNameClick(undefined);
          }}
          // contextualMenuItems={[
          //   {
          //     key: "openLinkInNewTab",
          //     text: t("crm.contextMenu.button.label.OpenLinkInNewTab"),
          //     onClick: () => {
          //       clientPartnerId && onPartnerNameClick(undefined, true);
          //     },
          //   },
          // ]}
        />
      </div>
      <div className={generalStyles.keyValueWrap} style={style?.numberArea}>
        {!hidePartnerNumberLabel && (
          <span className={generalStyles.keyText}>
            {t("crm.departments.PartnerNumber")}
          </span>
        )}
        <span
          style={
            hidePartnerNumberLabel
              ? {
                  color: theme.palette.neutralSecondary,
                  fontWeight: "normal",
                }
              : {}
          }
        >
          {clientPartnerNumber}
        </span>
      </div>
    </div>
  );
};

export default ClientBasicTableInfo;
