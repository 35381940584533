import axios from "axios";
import { IDocumentInfo } from "../types/types";

const url = process.env.REACT_APP_BASE_URL;

// const uadUrl = process.env.REACT_APP_BFF_HOST_UAD;

export const getDocumentsForInternalUsers = async ({
  clientId,
}: {
  clientId: number | string;
}) => {
  const res = await axios.get(`${url}/client/documentsinfo/${clientId}`);

  return res.data as IDocumentInfo[];
};

export const getDocumentsForExternalUsers = async () => {
  const res = await axios.get(`${url}/client/documentsinfo`);
  return res.data as IDocumentInfo[];
};

export const addDocument = async ({
  clientId,
  isInternalUserDocument,
  data,
}: {
  clientId: number | string;
  isInternalUserDocument: boolean;
  data: {
    name: string;
    base64String: string;
  };
}) => {
  const res = await axios.post(
    `${url}/client/document/${clientId}/${isInternalUserDocument}`,
    data
  );
  return res.data;
};
