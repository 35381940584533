import { call, fork, put, takeEvery } from "redux-saga/effects";
import * as API from "../../api/api";
import { loadTaxonomyActions, loadTaxonomyUADActions } from "./taxonomy";

export function* loadTaxonomySaga() {
  yield takeEvery(loadTaxonomyActions.trigger, function* () {
    try {
      yield put(loadTaxonomyActions.loading());
      const res = yield call(API.getTaxonomyData);
      yield put(loadTaxonomyActions.success(res.data));
    } catch (err) {
      yield put(loadTaxonomyActions.error(err));
    }
  });
}

export function* loadTaxonomyUADSaga() {
  yield takeEvery(loadTaxonomyUADActions.trigger, function* () {
    try {
      yield put(loadTaxonomyUADActions.loading());
      const res = yield call(API.getUADTaxonomy);
      yield put(loadTaxonomyUADActions.success(res.data));
    } catch (err) {
      yield put(loadTaxonomyUADActions.error(err));
    }
  });
}

export function* taxonomySaga() {
  yield fork(loadTaxonomySaga);
  yield fork(loadTaxonomyUADSaga);
}
