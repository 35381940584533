import { GrecoHeader } from "@greco/components";
import { ISettingsToggle } from "@greco/components/dist/components/GrecoHeader/GrecoPanel/Settings/Settings";
import { User } from "@microsoft/microsoft-graph-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { MSGraphAuth, logout } from "../../api/GraphService";
import Logo from "../../assets/GrECo_Logo_small.jpg";
import { headerConfig } from "../../config/headerConfig";
import { inContextEditorPostProcessor } from "../../i18n";
import { GosNavigation } from "./GosNavigation/GosNavigation";

type Props = {
  user: UserHeader | null;
  darkMode: any;
};

export type UserHeader = User & {
  photoSmall: string;
  photoMedium: string;
};

export const AppHeader = ({ user, darkMode }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [shouldShowPhrase, setShouldShowPhrase] = useState(false);

  const phraseToggle: ISettingsToggle = {
    title: "Phrase",
    toggleProps: {
      defaultChecked:
        !!new URLSearchParams(location.search).get("phrase_context") ||
        inContextEditorPostProcessor.phraseEnabled,
      onChange: (_event, checked) => {
        if (checked) {
          inContextEditorPostProcessor.phraseEnabled = true;
        } else {
          inContextEditorPostProcessor.phraseEnabled = false;
          const queryParams = new URLSearchParams(location.search);
          if (queryParams.get("phrase_context")) {
            queryParams.delete("phrase_context");
            navigate({
              pathname: location.pathname,
              search: queryParams.toString(),
            });
          }

          // Reload is needed because we have to remove
          // Phrase scripts from the client
          window.location.reload();
        }
      },
    },
  };

  const gosNav = (closeAppLuncher: () => void) => {
    return (
      <GosNavigation
        closeAppLuncher={closeAppLuncher}
        isRolesToggleButtonsFeatureFlagOn={false}
      />
    );
  };

  const enableInContextEditor = async () => {
    const account = await MSGraphAuth.accountObj;
    if (!(account && account.idTokenClaims)) return false;

    const roles: string[] = (account.idTokenClaims as { roles: string[] })
      .roles;
    return !!(roles && roles.includes("PHRASE"));
  };

  useEffect(() => {
    (async () => {
      setShouldShowPhrase(await enableInContextEditor());
      const grecoHeader = document.querySelector(".greco-header");

      if (grecoHeader && process.env.REACT_APP_HEADER_ORANGE_COLOR === "true") {
        grecoHeader.setAttribute("style", `background: #ea4300 !important`);
      }
    })();
  }, []);

  return (
    <GrecoHeader
      headerConfig={headerConfig}
      gosNavigation={gosNav}
      user={user}
      logo={Logo}
      logout={logout}
      headerStrings={{
        notificationsPanelStrings: {
          panelTitle: t("header.Notifications"),
          noNotificationsTitle: t("header.NoNotificationsTitle"),
          noNotificationsSubtitle: t("header.NoNotificationsSubtitle"),
        },
        helpPanelStrings: {
          panelTitle: t("header.Help"),
        },
        settingsPanelStrings: {
          panelTitle: t("header.Settings"),
        },
        userSettingsPanelStrings: {
          panelTitle: t("header.UserSettings"),
          myOfficeProfileLinkText: t("header.MyOfficeProfile"),
          myAccountLinkText: t("header.UserSettings"),
          signOutLinkText: t("header.SignOut"),
        },
        searchPlaceholder: t("header.Search"),
      }}
      darkMode={darkMode}
      additionalSettingsToggles={shouldShowPhrase ? [phraseToggle] : undefined}
    />
  );
};
