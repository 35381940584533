import { useTheme } from "@emotion/react";
import { mergeStyleSets } from "@fluentui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Select, { mergeStyles } from "react-select";
import ErrorMsg from "../../../components/controls/ErrorMsg";
import { FieldContainer } from "../../../components/controls/FieldContainer";
import { getSelectStyles } from "../../../utils/utils";

const getClassNames = (theme, required, disabled) =>
  mergeStyleSets({
    root: {
      width: "100%",
      marginTop: "2px",
    },
    label: {
      fontSize: "14px",
      fontWeight: "600",
      color: disabled ? theme.palette.neutralSecondaryAlt : theme.palette.black,
      padding: "5px",
    },
    required: {
      color: "rgb(164, 38, 44)",
      fontSize: 14,
      marginLeft: 3,
    },
  });

const FilterComboBoxField = ({
  setFieldValue,
  options,
  defaultValue,
  name,
  label,
  errors,
  transparent = false,
  disabled,
  required = false,
  isMarked = false,
  ...props
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isDarkMode = theme.isDark;
  const classes = getClassNames(theme, required, disabled);
  const hasError = errors[name];

  // useEffect(() => {
  //   value && value.value === "" ? setValue(value) : setValue(defaultValue);
  //   //setFieldValue(name, defaultValue);
  // }, [defaultValue]);

  return (
    <div className={classes.root}>
      <FieldContainer isTooltipHidden={disabled} tooltipText={label}>
        <label
          id="aria-label"
          htmlFor="aria-example-input"
          className={classes.label}
        >
          {t(label)}
          {required && <span className={classes.required}>*</span>}
        </label>

        <Select
          id={name + "_field"}
          menuShouldScrollIntoView
          styles={mergeStyles(getSelectStyles(!!errors, theme), {
            container: (p) => ({
              ...p,
              width: "100%",
            }),
            menuPortal: (p) => ({
              ...p,
              background: theme.palette.neutralLighter,
              zIndex: 9999999,
            }),
            menu: (p) => ({
              ...p,
              zIndex: 9999,
              background: theme.palette.neutralLighter,
            }),
            control: (p, state) => {
              return {
                ...p,
                background: state.isDisabled ? "transparent" : "transparent",
                ...(isMarked && { background: theme.palette.yellowLight }),
                borderBottom: hasError
                  ? `2px solid rgb(164, 38, 44) !important`
                  : state.isFocused
                  ? `2px solid rgb(0, 90, 161) !important`
                  : `1px solid ${theme.palette.black} !important`,
                boxShadow: "none",
                ...(state.isDisabled && {
                  borderBottom: `1px solid ${theme.palette.neutralLighter} !important`,
                }),
              };
            },
            option: (p, state) => ({
              ...p,
              background:
                state.isSelected || state.isFocused
                  ? theme.palette.neutralLighter
                  : theme.palette.white,
              "&:hover": {
                background: theme.palette.neutralLighter,
              },
              color: theme.palette.black,
              fontSize: "14px",
              fontWeight: 400,
            }),
            input: (p) => ({
              ...p,
              color: (function () {
                if (disabled) {
                  return theme.palette.neutralSecondaryAlt;
                }
                if (isDarkMode) {
                  if (isMarked) {
                    return "black";
                  } else {
                    return "white";
                  }
                }
                return theme.palette.black;
              })(),
            }),
            singleValue: (p, state) => ({
              ...p,
              color: state.isDisabled
                ? theme.palette.neutralSecondaryAlt
                : theme.palette.black,
              fontSize: "14px",
              fontWeight: 400,
            }),
            dropdownIndicator: (p, state) => ({
              ...p,
              ...(state.isDisabled && { visibility: "hidden" }),
            }),
            indicatorSeparator: (p, state) => ({
              ...p,
              ...(state.isDisabled && { visibility: "hidden" }),
            }),
          })}
          // className="basic-single"
          classNamePrefix="select"
          value={defaultValue}
          isDisabled={disabled}
          onChange={(option) => {
            //setValue(option);
            setFieldValue(name, { value: option, label: option });
          }}
          // isLoading={isLoading}
          isClearable={true}
          isSearchable={true}
          name={name}
          options={options}
        />

        {errors[name] && (
          <ErrorMsg id={name + "_error"}>{errors[name]}</ErrorMsg>
        )}
      </FieldContainer>
    </div>
  );
};

export default React.memo(FilterComboBoxField);
