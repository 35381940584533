import { useCallback, useEffect } from "react";

import {
  getDocumentsForExternalUsers,
  getDocumentsForInternalUsers,
} from "../api/documentatioApi";
import { useSelector } from "../store/hooks";

import useIsInternalUser from "./useIsInternalUser";

import { useDispatch } from "react-redux";
import { setDocumentConfiguration } from "../store/locations/locations";
import useIsExternalUser from "./useIsExternalUser";

function useDocumentList() {
  const dispatch = useDispatch();
  const internalUser = useIsInternalUser();
  const externalUser = useIsExternalUser();
  const client = useSelector((s) => s.clients);
  const authState = useSelector((s) => s.auth);
  const documents = useSelector((s) => s.locations.documentConfiguration);

  const clientId = client.clientId;
  const isLoggedIn = authState.isLoggedIn;

  const fetchDocumentFiles = useCallback(async () => {
    if (!isLoggedIn || (!internalUser && !externalUser)) return;

    try {
      let data;

      if (internalUser) {
        data = await getDocumentsForInternalUsers({ clientId });
      } else {
        data = await getDocumentsForExternalUsers();
      }
      dispatch(setDocumentConfiguration(data));
    } catch (error: any) {
      // toast.error(error.message);
      dispatch(setDocumentConfiguration([]));
    }
  }, [isLoggedIn, internalUser]);

  useEffect(() => {
    if (!isLoggedIn) return;
    fetchDocumentFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, internalUser]);

  return documents;
}

export default useDocumentList;
