import { useTheme } from "@emotion/react";
import {
  DirectionalHint,
  Panel,
  PanelType,
  PrimaryButton,
  Separator,
  TooltipHost,
} from "@fluentui/react";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
  deleteConfigurationSettings,
  setConfigurationSettings,
  updateConfigurationSettings,
} from "../../api/settingConfigurationApi";
import { useSelector } from "../../store/hooks";
import { setMailConfiguration } from "../../store/locations/locations";
import { EConfigurationSetting, IOriginLocationInfo } from "../../types/types";
import { toast } from "../FluentToast";
import "./NavigationPanel.scss";
import ResetMailConfig from "./ResetMailConfig";
import {
  BusinessObjectType,
  IDestinationLocationInfo,
  SearchList,
} from "./SearchList/SearchList";
import { ClientIcon } from "./icons/ClientIcon";
import { PolicyIcon } from "./icons/PolicyIcon";
import { ProjectIcon } from "./icons/ProjectIcon";

const typeOrder = [
  "partners",
  "policies",
  "projects",
  "mandates",
  "tasks",
  "claims",
  "virtualFolders",
];

export interface INavigationPanelProps {
  selectedCallback?: Function | undefined;
  handleToggleNavOpen: Function;
  showPanel: boolean;
  selectedLocations: any;
  title: string;
  originLocationInfo: IOriginLocationInfo;
  siteLocation: any;
  startAtCurrentLocation?: boolean;
}

const NavigationPanel = ({
  selectedCallback,
  handleToggleNavOpen,
  showPanel,
  selectedLocations,
  title,
  originLocationInfo,
  siteLocation,
  startAtCurrentLocation = true,
}: INavigationPanelProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [configSubmitting, setConfigSubmitting] = useState(false);
  const client = useSelector((s) => s.clients.client);
  const mailConfig = useSelector((s) => s.locations.mailConfiguration);
  const clientId = client?.clientId;
  const theme = useTheme();

  const [newFolderName, setNewFolderName] = useState<string>("");
  const [destination, setDestination] = useState<IDestinationLocationInfo>();
  const [module, setModule] = useState<BusinessObjectType>();
  const navigationRequiredRef = useRef(false);

  useEffect(() => {
    navigateToCurrent(siteLocation, undefined, startAtCurrentLocation);
  }, [showPanel]);

  useEffect(() => {
    navigationRequiredRef.current = true;
  }, []);

  const navigateToCurrent = (
    navigateTarget: any,
    workbenchStructure: any,
    startAtCurrentLocation: boolean,
    forceGet?: boolean
  ) => {
    if (!startAtCurrentLocation && !forceGet) return;
    navigateTarget = navigateTarget || siteLocation;
    let rez: any = false;
    for (let i in workbenchStructure) {
      rez = recursiveSearch(navigateTarget, workbenchStructure[i], [i]);
      if (rez) break;
    }

    if (forceGet) return workbenchStructure[rez[0]];
  };

  const recursiveSearch = (
    navigateTarget: any,
    workbenchItem: any,
    keyTrail: any
  ): any => {
    if (
      workbenchItem.containerId === Number(navigateTarget.cid) &&
      workbenchItem.containerTypeName.toLowerCase() ===
        navigateTarget.ctype.toLowerCase() &&
      (workbenchItem.virtualFolderId || 0) === navigateTarget.vFolder
    ) {
      return keyTrail;
    }
    let rez = false;
    let index = -1;
    typeOrder.forEach((key) => {
      if (rez || !workbenchItem[key]) return;
      workbenchItem[key].forEach((item: any) => {
        if (rez) return;
        index++;
        rez = recursiveSearch(navigateTarget, item, [...keyTrail, index + ""]);
      });
    });
    return rez;
  };

  const getInitialSlideIndex = (): number => {
    switch (module) {
      case "policies":
        return 0;
      case "clients":
        return 1;
      case "projects":
        return 2;
      default:
        return 0;
    }
  };

  const handleChooseLocation = async () => {
    if (destination) {
      const configurationSettingId = mailConfig?.configurationSettingId;

      try {
        setConfigSubmitting(true);

        const apiCall = configurationSettingId
          ? updateConfigurationSettings
          : setConfigurationSettings;
        const response = await apiCall({
          clientId,
          ...(configurationSettingId && {
            configurationSettingId,
          }),
          data: {
            clientId,
            locationId: null,
            settingName: EConfigurationSetting.Email,
            ...(configurationSettingId && {
              rowVersion: mailConfig.rowVersion,
            }),
            settingValue: JSON.stringify(destination),
          },
        });

        const data = {
          ...response,
          settingValue: JSON.parse(response.settingValue),
        };
        dispatch(setMailConfiguration(data));
        hidePanel();
        toast.success(t("rvm.success"));
      } catch (error: any) {
        toast.error(error?.message);
      } finally {
        setConfigSubmitting(false);
      }
    }
  };

  const handleResetLocation = async () => {
    if (destination) {
      const configurationSettingId = mailConfig.configurationSettingId;

      try {
        setConfigSubmitting(true);
        await deleteConfigurationSettings({
          clientId,
          configurationSettingId,
          rowVersion: mailConfig.rowVersion,
        });
        dispatch(setMailConfiguration(null));
        hidePanel();
        toast.success(t("rvm.success"));
      } catch (error: any) {
        toast.error(error?.message);
      } finally {
        setConfigSubmitting(false);
      }
    }
  };

  const hidePanel = () => {
    setNewFolderName("");
    setDestination(undefined);
    handleToggleNavOpen();
  };

  return (
    <Panel
      isOpen={showPanel}
      onOpen={() => {
        if (originLocationInfo?.containerTypeName === "Policy") {
          setModule("policies");
        } else if (originLocationInfo?.containerTypeName === "Project") {
          setModule("projects");
        } else {
          setModule("clients");
        }
        setDestination(undefined);
      }}
      isLightDismiss={true}
      onDismiss={hidePanel.bind(this)}
      type={PanelType.custom}
      customWidth="340px"
      headerText={title}
      className="navigation-panel dm-dialog"
    >
      <Slider
        {...{
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 1,
          focusOnSelect: true,
          centerMode: true,
          initialSlide: module ? getInitialSlideIndex() : 0,
        }}
        className="carousel"
      >
        <div
          onClick={() => {
            if (module === "clients") {
              setModule("clients");
            } else {
              setModule("clients");
              setDestination(undefined);
            }
          }}
          className={`${!!module && module !== "clients" ? "inactive" : ""}`}
        >
          <TooltipHost
            content={t("rvm.clientsList.clients.label")}
            directionalHint={DirectionalHint.bottomCenter}
          >
            <ClientIcon />
          </TooltipHost>
        </div>
        <div
          onClick={() => {
            if (module === "policies") {
              setModule("policies");
            } else {
              setModule("policies");
              setDestination(undefined);
            }
          }}
          className={`${!!module && module !== "policies" ? "inactive" : ""}`}
        >
          <TooltipHost
            content={t("rvm.client.sideBarPolicy")}
            directionalHint={DirectionalHint.bottomCenter}
          >
            <PolicyIcon />
          </TooltipHost>
        </div>

        <div
          onClick={() => {
            if (module === "projects") {
              setModule("projects");
            } else {
              setModule("projects");
              setDestination(undefined);
            }
          }}
          className={`${!!module && module !== "projects" ? "inactive" : ""}`}
        >
          <TooltipHost
            content={t("rvm.client.sideBarProjects")}
            directionalHint={DirectionalHint.bottomCenter}
          >
            <ProjectIcon />
          </TooltipHost>
        </div>
      </Slider>

      <SearchList
        chosenModule={module}
        onSelectDestination={(destinationX?: IDestinationLocationInfo) => {
          if (!_.isEqual(destinationX, destination))
            setDestination(destinationX);
        }}
        selectedDestination={destination}
      />
      <Separator />
      <div className="actions-container">
        <PrimaryButton
          disabled={configSubmitting || !destination}
          text={t("rvm.chooseThisLocation.label")}
          onClick={handleChooseLocation}
          allowDisabledFocus={true}
        />
        {mailConfig && (
          <ResetMailConfig
            handleReset={handleResetLocation}
            disabled={configSubmitting}
          />
        )}
      </div>
    </Panel>
  );
};

export default NavigationPanel;
