import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
}

export const InsurerIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '32'}
      height={props.height ? props.height : '30'}
      viewBox="0 0 32 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6062 10.0248H2.83618V24.5708H10.6062V10.0248Z"
        fill="#22AAE0"
      />
      <path d="M29.691 10.0248H21.921V24.5708H29.691V10.0248Z" fill="#1694A8" />
      <path
        d="M22.618 27.7455H30.2696C30.4289 27.7455 30.5584 27.6161 30.5584 27.457V8.4506C30.5584 8.0814 30.2596 7.78294 29.89 7.78294H22.5958V10.9212H28.1481V12.3328H22.5958V13.2746H28.1481V14.6873H22.5958V15.6291H28.1481V17.0418H22.5958V17.9836H28.1481V19.3963H22.5958V20.3381H28.1481V21.7509H22.5958V22.6927H28.1481V24.1054H22.5958V25.0472H22.618V26.4588H22.5958V26.7373H22.618V27.7455Z"
        fill="#137D9E"
      />
      <path
        d="M23.1434 7.78858H21.8862V27.7511H23.1434V7.78858Z"
        fill="#0B5D77"
      />
      <path
        d="M8.9626 27.7511V24.6681H8.9648V24.1066H3.41036V22.6939H8.9626V21.7521H3.41036V20.3394H8.9626V19.3976H3.41036V17.9849H8.9626V17.043H3.41036V15.6303H8.9626V14.6885H3.41036V13.2802H8.9626V12.3384H3.41036V10.9268H8.9626V7.78858H1.66844C1.29881 7.78858 1 8.08704 1 8.45625V27.4626C1 27.6218 1.12948 27.7511 1.28884 27.7511H8.9626V27.7511Z"
        fill="#0095E1"
      />
      <path
        d="M21.1582 7.64819H10.6005V23.4058H21.1582V7.64819Z"
        fill="#12CDF9"
      />
      <path
        d="M9.75605 7.78858H8.49887V27.7511H9.75605V7.78858Z"
        fill="#088BBF"
      />
      <path
        d="M21.8867 29.3993V4.35739C21.8867 4.05119 21.6776 3.78368 21.3799 3.70962L10.5853 1.02017C10.1636 0.915152 9.75525 1.23351 9.75525 1.66793V29.3993C9.75525 29.5584 9.88473 29.6878 10.0441 29.6878H21.5979C21.7572 29.6867 21.8867 29.5584 21.8867 29.3993ZM11.1154 8.62647H20.6716V10.0392H11.1154V8.62647ZM11.1154 10.9799H20.6716V12.3926H11.1154V10.9799ZM11.1154 13.3333H20.6716V14.746H11.1154V13.3333ZM11.1154 15.6867H20.6716V17.0994H11.1154V15.6867ZM11.1154 18.0401H20.6716V19.4528H11.1154V18.0401ZM11.1154 20.3935H20.738V21.8062H11.1154V20.3935Z"
        fill="#00B8EE"
      />
    </svg>
  );
};
