import { css } from "@emotion/css";
import { Label } from "@fluentui/react";
import { useField } from "formik";
import { formatDate, getDatePickerClearSuffix } from "../../utils/utils";
import { VSpace } from "../Spacer";
import { ErrorMessage, StyledDatePicker } from "../styled";
import { DateFieldConfig } from "./types";
import { useDynamicConfig } from "./useDynamicConfig";

type Props = { fieldConfig: DateFieldConfig };

export const DateField = ({ fieldConfig }: Props) => {
  const config = {
    ...fieldConfig,
    ...useDynamicConfig(fieldConfig.d),
  };
  const {
    name,
    label,
    isVisible = true,
    isDisabled,
    isRequired,
    validate,
    renderField,
    background,
  } = config;
  const [field, { error }, { setValue }] = useField({ name, validate });

  if (!isVisible) return null;

  const labelRender = (
    <Label required={isRequired} disabled={isDisabled}>
      {label}
    </Label>
  );

  const fieldRender = (
    <StyledDatePicker
      id={name}
      formatDate={formatDate}
      hasError={!!error}
      textField={{
        onRenderSuffix:
          !isDisabled && getDatePickerClearSuffix(field.value, setValue),
      }}
      {...field}
      onSelectDate={(date) => setValue(date)}
      disabled={isDisabled}
      background={background}
    />
  );

  const errorRender = <ErrorMessage>{error}</ErrorMessage>;

  return (
    <div
      className={css`
        flex: 1;
      `}
    >
      {labelRender}
      {renderField ? renderField({ field: fieldRender }) : fieldRender}
      {errorRender}
      <VSpace height={10} />
    </div>
  );
};
