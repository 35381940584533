import { Stack } from "@fluentui/react";
import React from "react";
import { darkTheme, lightTheme } from "../../utils/utils";
import { CommandBarHeight } from "../config/ui";

const inactive: React.CSSProperties = {
  opacity: 0.5,
  filter: "grayscale(100%)",
  pointerEvents: "none",
};

interface IVerticalSplitContainerProps {
  isReady: boolean;
  isInactive?: boolean;
  containerMinHeight?: number | string;
  containerWidth?: string;
  leftAreaWidth?: string;
  rightAreaWidth?: string;
  borderTopColor?: string;
  hideDefaultBorderTop?: boolean;
  leftAreaRender?: () => JSX.Element;
  rightAreaRender?: () => JSX.Element;
  leftAreaShimmer?: () => JSX.Element;
  rightAreaShimmer?: () => JSX.Element;
  marginTop?: string | number;
}

/**
 * @param {string | undefined} containerWidth width of main container.
 * @param {string | undefined} leftAreaWidth width of left area container, sub container of main container.
 * @param {string | undefined} rightAreaWidth width of right area container, sub container of main container.
 * @param {string | undefined} leftAreaRender Function (render prop) that returns JSX.Element which will be rendered inside of left area container.
 * @param {string | undefined} rightAreaRender Function (render prop) that returns JSX.Element which will be rendered inside of right area container.
 * @param {string | undefined} leftAreaShimmer Function (render prop) that returns JSX.Element which will be rendered inside of left area container, will component isn't ready.
 * @param {string | undefined} rightAreaShimmer Function (render prop) that returns JSX.Element which will be rendered inside of right area container, will component isn't ready.
 * @param {boolean} isReady Indicator is component ready, otherwise leftAreaShimmer and rightAreaShimmer will be shown, if they are provided.
 *
 * @returns {JSX.Element} Container (layout) that allows you to render different things in its left and right sub containers.
 */
export const VerticalSplitContainer = ({
  containerWidth,
  containerMinHeight,
  leftAreaWidth,
  rightAreaWidth,
  marginTop,
  borderTopColor,
  hideDefaultBorderTop,
  leftAreaRender,
  rightAreaRender,
  leftAreaShimmer,
  rightAreaShimmer,
  isReady,
  isInactive,
}: IVerticalSplitContainerProps): JSX.Element => {
  const theme =
    localStorage.getItem("darkMode") === "true" ? darkTheme : lightTheme;

  return (
    <div
      style={{
        width: containerWidth ? containerWidth : "100%",
        height: "100%",
        minHeight: containerMinHeight ?? "200px",
        margin: "2% auto 1%",
        marginTop: marginTop || "2%",
        position: "relative",
        borderRadius: "4px",
        boxShadow:
          "0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)",
        borderTop: `5px solid ${
          borderTopColor ||
          (!hideDefaultBorderTop && theme.palette.themePrimary)
        }`,
        background: theme.palette.white,
        ...(isInactive && inactive),
      }}
    >
      <Stack
        horizontal
        style={{ width: "100%", height: "100%", margin: 0, padding: 0 }}
      >
        {leftAreaRender && (
          <div
            style={{
              width: leftAreaWidth ? leftAreaWidth : "20%",
              borderRight: `1px solid ${theme.palette.neutralLight}`,
            }}
          >
            <div
              style={{
                position: "sticky",
                top: `${CommandBarHeight}px`,
              }}
            >
              {isReady
                ? leftAreaRender()
                : leftAreaShimmer
                ? leftAreaShimmer()
                : null}
            </div>
          </div>
        )}
        {rightAreaRender && (
          <div
            style={{
              width: rightAreaWidth ? rightAreaWidth : "80%",
              height: "100%",
            }}
          >
            {isReady
              ? rightAreaRender()
              : rightAreaShimmer
              ? rightAreaShimmer()
              : null}
          </div>
        )}
      </Stack>
    </div>
  );
};
