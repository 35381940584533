import { useTheme } from "@emotion/react";
import { DatePicker, Icon } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorMsg from "./ErrorMsg";
import { FieldContainer } from "./FieldContainer";

const datePickerStyles = (theme, isMarked) => ({
  root: {
    ".ms-TextField-fieldGroup": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
      background: "transparent !important",
      ...(isMarked && { background: theme.palette.yellowLight }),
    },
    ".ms-TextField-fieldGroup::after": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
    },
    ".ms-TextField-suffix": {
      //Cancel Icon
      marginRight: "30px",
      background: "none",
      cursor: "pointer",
    },
    ".msDatePickerDisabled": {
      color: "#005aa1",
    },
    ".ms-TextField-fieldGroup input": {
      background: "transparent !important",
      ...(isMarked && theme.isDark && { color: "black" }),
    },
  },
  container: {
    ".ms-DatePicker-goToday": {
      right: "14px",
    },
  },
  icon: { right: "0" },
});

const DatePickerField = ({
  defaultValue,
  disabled,
  label,
  placeholder,
  name = "",
  setFieldValue,
  errors,
  required = false,
  isMarked = false,
  ...props
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [value, setValue] = useState(defaultValue);
  const onFormatDate = (date?: Date): string => {
    return !date
      ? ""
      : ((date.getDate() + "").length === 1 ? "0" : "") +
          date.getDate() +
          "." +
          ((date.getMonth() + 1 + "").length === 1 ? "0" : "") +
          (date.getMonth() + 1) +
          "." +
          date.getFullYear();
  };
  const onParseDateFromString = React.useCallback(
    (newValue: string): Date => {
      const previousValue = value || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year += previousValue.getFullYear();
      }
      return new Date(year, month, day);
    },
    [value]
  );

  useEffect(() => {
    setValue(defaultValue);
    setFieldValue(name, defaultValue);
  }, [name, defaultValue]);

  return (
    <FieldContainer isTooltipHidden={disabled} tooltipText={label}>
      <DatePicker
        onSelectDate={(value) => {
          setValue(value ? value : "");
          setFieldValue(name, value ? value : "");
        }}
        styles={datePickerStyles(theme, isMarked)}
        textField={{
          name: name,
          required: required,
          onRenderSuffix: () => (
            <Icon
              style={{
                visibility: value && !disabled ? "visible" : "hidden",
              }}
              iconName="Cancel"
              onClick={() => {
                setValue(undefined);
                setFieldValue(name, undefined);
              }}
            />
          ),
          styles: { suffix: { padding: "0 4px" } },
        }}
        formatDate={onFormatDate}
        disabled={disabled}
        isRequired={required}
        label={t(label)}
        value={value}
        placeholder={placeholder}
        ariaLabel="Select a date"
        {...props}
        allowTextInput={true}
        disableAutoFocus={true}
        parseDateFromString={onParseDateFromString}
      />
      {errors[name] && <ErrorMsg id={name + "_error"}>{errors[name]}</ErrorMsg>}
    </FieldContainer>
  );
};

export default DatePickerField;
