import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getClientCampaignHistoryInfo,
  getLastClientCampaignInfo,
} from "../../api/campaignApi";
import { ICampaignInfo } from "../../types/types";

interface DefaultState {
  campaignInfo: null | ICampaignInfo;
  campaignHistory: null | ICampaignInfo;
  campaignDataLoading: boolean;
}

const initialState: DefaultState = {
  campaignInfo: null,
  campaignHistory: null,
  campaignDataLoading: true,
};

export const getCampaignInfo = createAsyncThunk(
  "campaign/getCampaignInfo",
  async ({ clientId }: { clientId: string }) => {
    const response = await getLastClientCampaignInfo({
      clientId,
    });

    return response;
  }
);

export const getCampaignHistory = createAsyncThunk(
  "campaign/getCampaignHistory",
  async ({ clientId }: { clientId: string }) => {
    const response = await getClientCampaignHistoryInfo({
      clientId,
    });

    return response;
  }
);

const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setCampaignInfo: (state, action) => {
      state.campaignInfo = action.payload;
    },
    setCampaignHistory: (state, action) => {
      state.campaignHistory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCampaignInfo.pending, (state, action) => {
      state.campaignDataLoading = true;
      state.campaignInfo = null;
    });
    builder.addCase(getCampaignInfo.fulfilled, (state, action) => {
      state.campaignInfo = action.payload;
      state.campaignDataLoading = false;
    });
    builder.addCase(getCampaignInfo.rejected, (state, action) => {
      state.campaignDataLoading = false;
      state.campaignInfo = null;
    });
    //

    builder.addCase(getCampaignHistory.pending, (state, action) => {
      state.campaignDataLoading = true;
      state.campaignHistory = null;
    });
    builder.addCase(getCampaignHistory.fulfilled, (state, action) => {
      state.campaignHistory = action.payload;
      state.campaignDataLoading = false;
    });
    builder.addCase(getCampaignHistory.rejected, (state, action) => {
      state.campaignDataLoading = false;
      state.campaignHistory = null;
    });
  },
});

export const selectCampaignInfo = (state) => state.campaign?.campaignInfo;
export const selectCampaignHistory = (state) => state.campaign?.campaignHistory;

export const { setCampaignInfo, setCampaignHistory } = campaignSlice.actions;

export default campaignSlice;
