import {
  AuthenticationParameters,
  AuthError,
  Configuration,
  UserAgentApplication,
} from "msal";
import { getCookieValue } from "../utils";

const appId = process.env.REACT_APP_CLIENT_ID!;
export const redirectUri = window.location.origin + "/";
const authorityUrl = process.env.REACT_APP_AUTHORITY_URL!;
const signInPolicy = process.env.REACT_APP_SIGN_IN_POLICY;
export const cacheLocation = sessionStorage;

const msalConfiguration: Configuration = {
  auth: {
    clientId: appId,
    authority: `${authorityUrl}/${signInPolicy}`,
    validateAuthority: false,
    redirectUri,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

const loginRequest: AuthenticationParameters = {
  scopes: [appId],
};

const tokenRequest: AuthenticationParameters = {
  scopes: [appId],
  //forceRefresh: true,
};

const getAuthParams = (lang?: string): AuthenticationParameters => {
  const extraQueryParameters: AuthenticationParameters["extraQueryParameters"] =
    {};
  if (lang) {
    extraQueryParameters.lang = lang;
  }
  return {
    scopes: [appId],
    extraQueryParameters,
  };
};

export let msal: UserAgentApplication;

try {
  msal = new UserAgentApplication(msalConfiguration);
  msal.handleRedirectCallback((err: AuthError, res: any) => {
    console.log("handleRedirectError", err);
    console.log("handleRedirectResult", res);
    if (!err) return;
    // if (err.toString().indexOf("AADB2C90118") !== -1) {
    //   window.location.href = getForgotPasswordLink(langMap[i18next.language]);
    // }
  });
} catch (err) {
  if (
    err.toString() ===
    "AuthError: Unexpected error in authentication.: Hash does not contain state."
  ) {
    window.location.href = redirectUri;
  }
}

export const getAccount = () => msal.getAccount();

export const getToken = async () => {
  return msal
    .acquireTokenSilent(tokenRequest)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Token silent error", err);
      if (err.toString().includes("ClientAuthError: User login is required.")) {
        const isForgotPassword =
          getCookieValue("msal.error.description") ===
          "AADB2C90118: The user has forgotten their password.";
        if (!isForgotPassword) {
          login();
        }
      } else {
        login();
      }
    });
};

export const login = (lang?: string) => {
  msal.loginRedirect(getAuthParams(lang));
};

export const loginPopup = () => {
  return msal.loginPopup(loginRequest);
};

export const logout = () => {
  msal.logout();
};

export const checkIsLoggedIn = async () => {
  return !!getAccount();
};

export const extractMSALToken = () => {
  const timestamp = Math.floor(new Date().getTime() / 1000);
  let token = null;

  for (const key of Object.keys(localStorage)) {
    if (key.includes('"authority":')) {
      const val: any = JSON.parse(localStorage.getItem(key)!);

      if (val.expiresIn) {
        // We have a (possibly expired) token

        if (val.expiresIn > timestamp && val.idToken === val.accessToken) {
          // console.log(key);
          // Found the correct token
          token = val.idToken;
        } else {
          // console.log("will remove " + key);
          // Clear old data
          localStorage.removeItem(key);
        }
      }
    }
  }

  return token;
};
