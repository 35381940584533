import { useSelector } from "../store/hooks";

function useIsExternalUser(): boolean {
  const userRole = useSelector((s) => s.userRole.userRole);

  const isExternalUser = userRole === "external";
  return isExternalUser;
}

export default useIsExternalUser;
