import { DateFilterOperator, NumberFilterOperator } from "./columns";

export interface Client {
  clientId: number;
  clientName: string;
  parentClientId: number;
  [key: string]: any;
}

export interface RamUser {
  userId: string;
  userEmailAddress: string;
  userName: string;
}

export type ExcelReportResponse = {
  name: string;
  url: string;
};
export interface LocationSummary {
  clientLocation: ClientLocation;
  numberOfRecommendations: number;
  implementationStatusSummary: Summary[];
  prioritySummary: Summary[];
  priorityCompanySummary: Summary[];
  categoryOfMeasureSummary: Summary[];
  assessmentCategorySummary: Summary[];
  reductionCostSummary: Summary[];
}
export interface Location {
  locationId: number;
  currentVersionNumber: number;
  webBasePartnerNumber: string;
  locationName: string;
  locationLastModifiedAt: Date;
  locationLastModifiedByUserId: string;
  locationCreatedAt: Date;
  locationCreatedByUserId: string;
  countryCode: number;
  siccode: null;
  postalCode: string;
  city: string;
  latitude: null;
  longitude: null;
  turnoverInMioEur: number;
  tsiinMioEur: number;
  pmlinMioEur: null;
  emlinMioEur: null;
  isDeleted: boolean;
  rowVersion: string;
}

export interface ClientLocation {
  locationId: number;
  currentVersionNumber: number;
  webBasePartnerNumber: number;
  locationName: string;
  locationLastModifiedAt: Date;
  locationLastModifiedByUserId: string;
  locationCreatedAt: Date;
  locationCreatedByUserId: string;
  countryCode: number;
  siccode: number | null;
  siccodes: number[] | null;
  postalCode: string;
  city: string;
  latitude: null;
  longitude: null;
  turnoverInMioEur: number;
  tsiinMioEur: number;
  pmlinMioEur: number | null;
  emlinMioEur: number | null;
  isDeleted: boolean;
  assessmentPeriodStartedAt: string | null;
  rowVersion: string;
  address: string | null;
  [key: string]: any;
}

export interface Summary {
  code: number;
  numberOfEntries: number;
  numberOfEntriesPercentage: number;
}

export interface ClientLocationWithUsers {
  clientLocation: ClientLocation;
  riskUsers: RiskUser[];
}

export interface RiskUser {
  userId: string;
  userEmailAddress: string;
  userName: string;
  locationId: number;
  isInternal: boolean;
  rowVersion: string;
  isLimited: boolean;
  shouldReceiveNotifications: boolean;
}

export interface LocationDefaultValue {
  locationId: number;
  subcategoryCode: number;
  isUsed: boolean;
  defaultWeighting: number;
}

export type TaxonomyResponse = {
  Country: Taxonomy[];
  RiskAssessmentCategory: Taxonomy[];
  RiskAssessmentSubcategory: Taxonomy[];
  RiskCategoryOfMeasure: Taxonomy[];
  RiskImplementationStatus: Taxonomy[];
  RiskImprovementPotential: Taxonomy[];
  RiskPriority: Taxonomy[];
  RiskRating: Taxonomy[];
  RiskRecommendationStatus: Taxonomy[];
  RiskReductionCost: Taxonomy[];
  RiskReportCreator: Taxonomy[];
  SicCode: Taxonomy[];
};

export type Taxonomy = {
  code: string;
  id: number;
};
export interface RiskInsurerUser {
  userId: string;
  userEmailAddress: string;
  userName: string;
  locationId: number;
  rowVersion: string;
}

export type UserRole = "internal" | "external";

export interface TaxonomyUADResponse {
  ApplicationCode: Taxonomy[];
  ApplicationSetting: Taxonomy[];
  Country: Taxonomy[];
  CostCenter: Taxonomy[];
  Gender: Taxonomy[];
  SupportedLanguage: Taxonomy[];
  LanguageListType: Taxonomy[];
}

export interface Setting {
  userId: number;
  appSettingCodeId: number;
  userAppSettingValue: string;
  userAppSettingId: number;
  userAppSettingName: string;
  userAppSettingDescription: string;
  applicationCodeId: number;
  isDefault: boolean;
}

export type SettingsResponse = Setting[];

export interface BackendError {
  statusCode: number;
  errorCode: string;
  type: string;
  correlationID: string;
  messageCode: string;
  messageVariables: any[];
  statusDescription: string;
  message: string;
  formatedMessage: string;
  exceptionMessage: string;
  stackTrace: string;
}

export interface IDocument {
  name: string;
  value: string;
  path: any;
  driveId: string | undefined;
  iconName: string | undefined;
  fileType: string | undefined;
  fileExtension: string;
  originalExtension?: string;
  modifiedBy: string | undefined;
  documentSizeBytes: Number;
  fileSize: any;
  fileSizeRaw: any;
  author: string;
  authorImage: string | undefined;
  previewImageSrc: string | undefined;
  downloadUrl: string | undefined;
  webUrl: string | undefined;
  folderId: string;
  virtualFolderId: Number;
  details: IDocumentDetails;
  documentDateValue: number;
  documentDate: string;
  modifiedDate?: string;
  lastModifiedDate?: string;
  policyVersion?: any;
  tasksCount?: any;
  pdfEdit?: any;
  emailTemplate?: boolean;
  rowVersion?: string;
  documentTypeDefinitionID?: number;
  itemStatus: string;
  allowedGroupd: any[] | undefined;
  createdDateUTZ: string;
  createdtDateUTC: string;
  docGroupEmpty?: boolean;
  hasAttachment?: boolean;
  businessObjectID?: string | number;
  isUploadedByClient?: boolean;
  specialAccessGroupId?: number | string;
  createdByUserAADGUID?: string;
  documentNumber?: number;
  isResultInAttachment?: boolean;
  permanently?: boolean;
  note?: string | null;
}

export interface IDocumentDetails {
  comment: string;
  subject: string;
  documentId: number;
  appDocumentDate: string;
  isMain: boolean;
  depthLevel?: number;
  driveItemId: string;
  subcategoryId: number;
  rowVersion?: string;
  documentExtensionCode?: string;
  formDefinition?: any;
  summary?: any;
}

export interface ILocationRestriction {
  containerId?: boolean;
  containerTypeName?: boolean;
  virtualFolderId?: boolean;
  root?: boolean;
  invert?: boolean;
  containetNameValue?: string[];
}
export interface IOriginLocationInfo {
  containerId: number;
  containerTypeName: string;
  partnerTypeName?: string;
  sectionId?: number;
  sectionTypeName?: string;
  virtualFolderId?: number;
}
export type NavOperationType = "CopyTo" | "MoveTo" | "CopyAsPDF" | "Unknown";

export type NumberFilter = {
  type: "number" | "number[]";
  operator: NumberFilterOperator;
  number1: string | number | null | number[];
  number2: string | number | null;
  /** False by default */
  isNegativeAllowed?: boolean;
  max?: number;
  /** True by default */
  includeThousandSeparators?: boolean;
  includeNumber1?: boolean;
  includeNumber2?: boolean;
  /** Float by default */
  format?: "float" | "integer";
};
export type DateFilter = {
  type: "date";
  operator: DateFilterOperator;
  date1: Date | null;
  date2: Date | null;
};

export type BooleanFilter = {
  type: "boolean";
  unfilteredValue?: boolean | null;
  value: boolean | "any" | null;
};

export type TextFilter = {
  type: "text";
  value: string;
};

export type numberFieldValueFormat =
  | "amount"
  | "amountAllowNegative"
  | "withoutLeadingZero"
  | "nonNegativeInteger";

export interface VirtualFolder {
  folderDepthLevel: number;
  folderName: string;
  folderNameWithFullPath: string;
  hasSubfolders: boolean;
  parentFolderID: number | null;
  virtualFolderID: number;
}

export interface IMailConfiguration {
  configurationSettingId: number;
  clientId: number;
  locationId: any;
  settingName: string;
  settingValue: SettingValue;
  lastModifiedAt: string;
  lastModifiedByUserId: string;
  lastModifiedByUserName: any;
  lastModifiedByUserEmail: any;
  createdAt: string;
  createdByUserId: string;
  createdByUserName: any;
  createdByUserEmail: any;
  isDeleted: boolean;
  rowVersion: string;
}

export interface IDocumentInfo {
  base64String: null | string;
  name: string;
  url: string;
  Url?: string;
  Name?: string;
}

export interface SettingValue {
  containerId: number;
  containerTypeName: string;
  containerName: string;
  virtualFolderId: number;

  partnerTypeName?: string;
  sectionId?: number;
  sectionTypeName?: string;
}

export enum EBusinessObjectType {
  CompanyClient = "BusinessObjectType.CompanyClient",
  Department = "BusinessObjectType.Department",
}

export interface IConfigurationSettings {
  clientId: number | string;
  locationId: number;
  settingName: string;
  settingValue: string;
  lastModifiedAt?: string;
  lastModifiedByUserId?: string;
  lastModifiedByUserName?: string;
  lastModifiedByUserEmail?: string;
  createdAt?: string;
  createdByUserId?: string;
  createdByUserName?: string;
  createdByUserEmail?: string;
  rowVersion?: string;
}

export interface IDocumentConfigurationSettings
  extends Omit<IConfigurationSettings, "settingValue"> {
  clientId: number | string;
  configurationSettingId?: number;
  settingValue: {
    InternalUserDocuments: { Name: string; Url: string }[];
    ExternalUserDocuments: { Name: string; Url: string }[];
  };
}

export interface ICampaignInfo {
  clientCampaignHistoryId: number;
  clientId: number;
  isCampaignClosed: boolean;
  comment: string;
  isLast: boolean;
  setAt: string;
  setByUserId: string;
  setByUserName: string;
  setByUserEmail: string;
  isDeleted: boolean;
  rowVersion: string;
}

export enum EConfigurationSetting {
  Email = "DMSEmailLocationInfo",
  Document = "ClientDocumentsInfo",
}
