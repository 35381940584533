import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  CheckboxVisibility,
  IColumn,
  Icon,
  ScrollablePane,
  SelectionMode,
  mergeStyleSets,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ExtendableColumnItem from "./ExtendableColumnItem/ExtendableColumnItem";
import { List } from "./List/List";
import { ContactPersonIcon } from "./Sidebar/componentIcons/ContactPersonIcon";
type Props = {
  hideLocationName?: boolean;
  selection: any;
};

export const PinLocationsList = ({
  selection,
  hideLocationName,
  ...props
}: Props) => {
  const [columns, setColumns] = useState<any[]>([]);
  const { t } = useTranslation();
  const theme = useTheme();
  const isDarkMode = useTheme().isDark;

  const getClassNames = (status, theme) =>
    mergeStyleSets({
      container: {
        position: "relative",
        height: `${
          54 + selection.length * 50 > 280 ? 280 : 54 + selection.length * 50
        }px`,
      },
    });

  const classes = getClassNames(isDarkMode, theme);
  const PinIcon = styled(Icon)`
    font-size: 14px;
    color: rgb(0, 184, 238);
  `;

  useEffect(() => {
    let columns = [
      {
        key: "contactPerson",
        name: t("rvm.contactPerson.label"),
        fieldName: "contactPerson",
        label: t("rvm.contactPerson.label"),
        isVisible: true,
        isSortedDescending: false,
        isSorted: false,
        minWidth: 180,
        maxWidth: hideLocationName && hideLocationName === true ? 255 : 240,
        onRender: (item?: any, index?: number, column?: IColumn) => {
          return (
            <ExtendableColumnItem
              items={item.location.contactPersons?.map((person: any) => ({
                value: (
                  <>
                    <ContactPersonIcon width="15" height="15" />
                    &nbsp;
                    {(person.leadingTitle ? person.leadingTitle : "") +
                      " " +
                      (person.firstName ? person.firstName : "") +
                      " " +
                      (person.lastName ? person.lastName : "")}
                  </>
                ),
              }))}
            />
          );
        },
      },
      {
        key: "contactEmail",
        name: t("rvm.contactEmail.label"),
        fieldName: "contactEmail",
        label: t("rvm.contactEmail.label"),
        isVisible: true,
        isSortedDescending: false,
        isSorted: false,
        minWidth: 160,
        maxWidth: 210,
        onRender: (item?: any, index?: number, column?: IColumn) => {
          return (
            <ExtendableColumnItem
              items={item.location.contactPersons?.map((person: any) => ({
                value:
                  person.partnerMails && person.partnerMails.length > 0
                    ? person.partnerMails[0].emailAddress
                    : "",
              }))}
            />
          );
        },
      },
    ];
    if (!hideLocationName || hideLocationName !== true) {
      columns.unshift({
        key: "locationName",
        name: t("rvm.location.label"),
        fieldName: "locationName",
        label: t("rvm.location.label"),
        isVisible: true,
        isSortedDescending: false,
        isSorted: false,
        minWidth: 130,
        maxWidth: 190,
        onRender: (item?: any, index?: number, column?: IColumn) => {
          return <div>{item.location.locationName}</div>;
        },
      });
    }
    setColumns(columns);
  }, [t]);
  return (
    <div className={classes.container}>
      <ScrollablePane>
        <List
          checkboxVisibility={CheckboxVisibility.onHover}
          listClassName="crmList"
          scrollablePaneClassName="crmScrollablePane"
          listItems={selection}
          listStyles={{}}
          scrollablePaneStyle={{
            root: { top: "45px" },
            stickyBelowItems: { display: "none" },
          }}
          columns={columns}
          setColumns={setColumns}
          selectionType={SelectionMode.none}
          // contextMenuItems={(
          //   itemSelection: IObjectWithKey[]
          // ) => {
          //   return generateContextMenu(itemSelection);
          // }}
          onItemInvoked={() => {}}
          // alternateRowColors={{
          //   oddColor: theme.palette.white,
          //   evenColor: theme.palette.themeLighterAlt,
          // }}
          shouldShowStickyHeader={true}
          rowStyles={{
            root: {
              color: theme.palette.neutralPrimary,
              fontWeight: 500,
              fontSize: "1.175em",
            },
          }}
          isLoading={false}
          shimmerLineLength={5}
          removeFadingOverlay={false}
          // sortCallback={(sort: string) => {
          //   setSort(sort);
          //   getSyncedPartnersFn();
          // }}
          isSortClickable={true}
          // contextMenuOnClick={contextMenuOnClick}
          isRightClickContextMenuDisabled={true}
          emptyListMessage={t("rvm.NoRecords")}
          // loadMore={{
          //   numberOfItemsToShow: top,
          //   distanceFromBottom: "10px",
          //   observerElementClassName: "clientsObserver",
          //   updateSkip: setSkip,
          //   skip: skip,
          //   itemCount: crmContext.state.clientsCount,
          //   isLoading: listLoading || loadingUserViews,
          // }}
        />
      </ScrollablePane>
    </div>
  );
};

export default PinLocationsList;
