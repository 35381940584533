import { Persona, PersonaSize } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { darkTheme, lightTheme } from "../../../utils/utils";

interface ILogPersonaProps {
  name: string;
  id?: string;
  selectedPerson?: any;
}

interface ISelectedPersonData {
  displayName?: string;
  email?: string;
  personID?: string;
  phone?: string | number | null;
  imageUrl?: string;
}

const LogPersona = ({ name, selectedPerson }: ILogPersonaProps) => {
  const theme =
    localStorage.getItem("darkMode") === "true" ? darkTheme : lightTheme;

  const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);
  const [selectedPersonData, setSelectedPersonData] =
    useState<ISelectedPersonData>();

  useEffect(() => {
    if (selectedPerson) {
      setSelectedPersonData(selectedPerson);
      setImageUrl(selectedPerson?.imageUrl);
    }
  }, [selectedPerson]);

  const persona = (
    <Persona
      styles={{
        root: { marginBottom: 20, marginRight: 10, marginTop: 10 },
        details: {
          backgroundColor: theme.palette.neutralLight,
          color: theme.palette.neutralLight,
          padding: "5px 20px",
          position: "relative",
          left: -10,
          width: "auto",
          borderTopRightRadius: 25,
          borderBottomRightRadius: 25,
        },
        primaryText: {
          color: theme.palette.neutralDark,
          cursor: "pointer",
        },
      }}
      coinProps={{
        styles: { coin: { zIndex: 1, cursor: "pointer" } },
      }}
      text={name}
      imageUrl={imageUrl}
      size={PersonaSize.size32}
    />
  );

  return persona;
};

export default LogPersona;
