import { mergeStyleSets } from "@fluentui/react";
import { darkTheme, lightTheme } from "../../utils/utils";

export const TitleWithSection = ({
  content,
  icon,
  styles,
  title,
  hideHorizontalLine,
  hideTitle,
}: any): JSX.Element => {
  const theme =
    localStorage.getItem("darkMode") === "true" ? darkTheme : lightTheme;
  //Add style props if needed
  const titleWithSectionStyles = mergeStyleSets({
    wrap: {
      padding: "0 40px",
      ...styles?.wrap,
    },
    titleWrap: {
      display: "flex",
      alignItems: icon ? "center" : "flex-end",
      ...styles?.titleWrap,
    },
    icon: {
      ...styles?.icon,
    },
    title: {
      fontSize: "1rem",
      fontWeight: "600",
      marginRight: "15px",
      color: theme.palette.neutralPrimary,
      ...styles?.title,
    },
    line: {
      width: "100%",
      background: theme.palette.neutralTertiaryAlt,
      border: 0,
      height: "2px",
      ...styles?.line,
    },
    lineWrap: {
      flexGrow: "1",
      ...styles?.lineWrap,
    },
    content: {
      padding: "25px",
      ...styles?.content,
    },
  });

  return (
    <div className={titleWithSectionStyles.wrap}>
      {!hideTitle && (
        <div className={titleWithSectionStyles.titleWrap}>
          {icon && <div className={titleWithSectionStyles.icon}>{icon}</div>}
          <div className={titleWithSectionStyles.title}>{title}</div>
          {!hideHorizontalLine && (
            <div className={titleWithSectionStyles.lineWrap}>
              <hr className={titleWithSectionStyles.line} />
            </div>
          )}
        </div>
      )}
      <div className={titleWithSectionStyles.content}>
        {content && content()}
      </div>
    </div>
  );
};
