import { css } from "@emotion/react";
import { Overlay, Spinner, SpinnerSize } from "@fluentui/react";

export const FormSpinner = () => {
  return (
    <Overlay
      css={css`
        z-index: 3;
        background-color: transparent;
      `}
    >
      <Spinner
        size={SpinnerSize.large}
        css={css`
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          .ms-Spinner-circle {
            border-color: #005aa1 rgb(199, 224, 244) rgb(199, 224, 244);
          }
        `}
      />
    </Overlay>
  );
};
