import grecoTrianglesSVG from "./resources/icons/svg/GrECoGraphicTriangles.svg";
import branchOfficeSVG from "./resources/icons/svg/branchOffice.svg";
import contactPersonSVG from "./resources/icons/svg/contactPerson.svg";
import cooperationAgreement from "./resources/icons/svg/cooperationAgreement.svg";
import corporateSVG from "./resources/icons/svg/corporate.svg";
import departmentSVG from "./resources/icons/svg/department.svg";
import wordSVG from "./resources/icons/svg/docx.svg";
import insurerSVG from "./resources/icons/svg/insurer.svg";
import intermediarySVG from "./resources/icons/svg/intermed.svg";
import mandateSVG from "./resources/icons/svg/mandate.svg";
import powerpointSVG from "./resources/icons/svg/pptx.svg";
import folderSimplifiedSVG from "./resources/icons/svg/simplified.svg";
import templateSVG from "./resources/icons/svg/template.svg";
import excelSVG from "./resources/icons/svg/xlsx.svg";

export const IconsSVG = {
  docx: wordSVG,
  xlsx: excelSVG,
  pptx: powerpointSVG,
  template: templateSVG,
  grecoTriangles: grecoTrianglesSVG,
  folderSimplified: folderSimplifiedSVG,
  branchOffice: branchOfficeSVG,
  department: departmentSVG,
  contactPerson: contactPersonSVG,
  corporate: corporateSVG,
  intermediary: intermediarySVG,
  insurer: insurerSVG,
  mandate: mandateSVG,
  cooperation: cooperationAgreement,
};
