import React from "react";

interface iconProps {
  height?: string;
  width?: string;
  className?: string;
}

export const HistoryIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : "29"}
      height={props.height ? props.height : "29"}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14.5" cy="14.5" r="14.5" fill="#00B8EE" />
      <path
        d="M13.6536 10.8429V15.6855L17.7988 18.1455L18.4961 16.9688L15.1063 14.9591V10.8429H13.6536Z"
        fill="#5AE0FF"
      />
      <path
        d="M14.6174 6C9.799 6 5.90556 9.90312 5.90556 14.7167H3L6.77237 18.489L6.84015 18.6295L10.7482 14.7167H7.84261C7.84261 10.9734 10.8789 7.93705 14.6222 7.93705C18.3655 7.93705 21.4018 10.9734 21.4018 14.7167C21.4018 18.46 18.3655 21.4963 14.6222 21.4963C12.7481 21.4963 11.0581 20.7312 9.83291 19.506L8.46245 20.8764C10.0363 22.4551 12.2106 23.4333 14.6174 23.4333C19.4358 23.4333 23.3389 19.5302 23.3389 14.7167C23.3389 9.90312 19.4358 6 14.6174 6Z"
        fill="#5AE0FF"
      />
    </svg>
  );
};
