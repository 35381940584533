import { CommandBarButton, IIconProps, mergeStyleSets } from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "../../../store/hooks";
import ConfigDocDialog from "./ConfigDocDialog";

const configIcon: IIconProps = { iconName: "DocumentManagement" };

function ConfigureDocSettings() {
  const { t } = useTranslation();

  const client = useSelector((s) => s.clients.client);
  const clientId = client?.clientId;

  const [showDialog, setShowDialog] = useState(false);

  const handleToggleShowDialog = useCallback(() => {
    setShowDialog((prev) => !prev);
  }, []);

  return (
    <>
      <ConfigDocDialog
        showDialog={showDialog}
        toggleShowDialog={handleToggleShowDialog}
      />
      <CommandBarButton
        disabled={!clientId}
        onClick={handleToggleShowDialog}
        iconProps={configIcon}
        className={classes.buttonClass}
        text={t("rvm.configDocuments.label")}
      />
    </>
  );
}

export default ConfigureDocSettings;

const classes = mergeStyleSets({
  buttonClass: {
    display: "flex",
    alignItems: "center",
    height: 32,
    ".ms-Button-icon": {
      height: "inherit",
      display: "flex",
      alignItems: "center",
    },
  },
});
