import React, { useEffect, useState } from "react";

import { Toggle } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import { getUserRoles } from "../../../api/api";
import { handleAxiosError } from "../../../store/util";
import { navigationLinkInterface } from "./GosNavigationInterfaces";
import NavigationComponent from "./NavigationComponent";
import { NavigationLinks } from "./NavigationLinks";

interface Props {
  isRolesToggleButtonsFeatureFlagOn: boolean;
  closeAppLuncher: () => void;
}

export const GosNavigation = ({
  isRolesToggleButtonsFeatureFlagOn,
  closeAppLuncher,
}: Props) => {
  const { t } = useTranslation();
  const [userRoles, setUserRoles] = useState([]);

  const fetchUserRoles = async () => {
    try {
      const roles = await getUserRoles();
      setUserRoles(roles);
    } catch (error) {
      handleAxiosError(error);
    }
  };

  useEffect(() => {
    fetchUserRoles();
  }, []);

  return (
    <>
      <h2 style={{ paddingLeft: "15px" }}>{t("rvm.appLauncher.header")}</h2>
      <div
        style={{
          padding: "12px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            {NavigationLinks(t).map(
              (link: navigationLinkInterface, index: number) => {
                return (
                  <NavigationComponent
                    key={link.id}
                    id={link.id}
                    link={link}
                    index={index}
                    closeAppLuncher={closeAppLuncher}
                  />
                );
              }
            )}
          </div>
          {isRolesToggleButtonsFeatureFlagOn && (
            <div style={{ marginTop: 20 }}>
              <Toggle
                label={
                  allRoles.length === userRoles.length
                    ? "Deselct all"
                    : "Select all"
                }
                styles={{
                  root: { marginBottom: 20 },
                  label: { width: 100 },
                }}
                inlineLabel
                checked={allRoles.length === userRoles.length}
                onText="On"
                offText="Off"
                onChange={(_, checked) => {
                  setUserRoles(
                    allRoles.length === userRoles.length
                      ? []
                      : allRoles.map((r) => `GOS-${r}`)
                  );
                }}
              />
              {allRoles.map((role) => {
                return (
                  <Toggle
                    key={role}
                    label={role}
                    styles={{ label: { width: 100 } }}
                    inlineLabel
                    checked={userRoles.includes(`GOS-${role}`)}
                    onText="On"
                    offText="Off"
                    onChange={(_, checked) => {
                      let roles = [...userRoles];

                      if (checked) {
                        roles.push(`GOS-${role}`);
                      } else {
                        roles = roles.filter((r) => r !== `GOS-${role}`);
                      }

                      setUserRoles(roles);
                    }}
                  />
                );
              })}
            </div>
          )}
        </>
      </div>
    </>
  );
};

const allRoles = ["MGMT", "SD1", "SD2", "RIT", "REVMGMT", "REOADM", "IT"];
