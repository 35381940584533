import React from 'react';
interface Props {
  height?: string;
  width?: string;
  color?: string;
}

const WebReportsIcon = ({
  width = '26',
  height = '25',
  color
}: Props): JSX.Element => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0503 10.0988H5.26996V21.429H10.0503V10.0988Z"
        fill="url(#paint0_linear_1083_4204)"
      />
      <path
        d="M16.7814 13.1317H12.0011V21.4295H16.7814V13.1317Z"
        fill="url(#paint1_linear_1083_4204)"
      />
      <path
        d="M23.5313 9.31421H18.751V21.4293H23.5313V9.31421Z"
        fill="url(#paint2_linear_1083_4204)"
      />
      <path
        d="M3.9781 19.7191V2.32328C3.9781 2.14468 3.83191 2 3.65079 2H2.32667C2.14619 2 2 2.14468 2 2.32328V21.9981H2.01488V22H24.9604C25.1408 22 25.2877 21.8553 25.2877 21.6767V20.3663C25.2877 20.1877 25.1415 20.043 24.9604 20.043H4.30542C4.12429 20.0424 3.9781 19.8977 3.9781 19.7191Z"
        fill="#8A13AE"
      />
      <path
        d="M14.1602 11.6472C13.9966 11.6472 13.8329 11.6005 13.69 11.5044L6.13656 6.45162C5.75232 6.19492 5.65141 5.6783 5.91145 5.29805C6.17084 4.91779 6.69286 4.81792 7.07645 5.07527L14.1053 9.77727L21.3553 3.73861C21.7098 3.44349 22.2395 3.4883 22.5377 3.83911C22.8359 4.18992 22.7907 4.71422 22.4362 5.00933L14.7004 11.4519C14.5451 11.5813 14.353 11.6472 14.1602 11.6472Z"
        fill="#AB30C5"
      />
      <path
        d="M19.8611 2.83679H23.2125C23.2894 2.83679 23.3515 2.89825 23.3515 2.97443V6.29112C23.3515 6.41339 23.2021 6.47484 23.1141 6.38842L19.7627 3.07173C19.6754 2.98531 19.7375 2.83679 19.8611 2.83679Z"
        fill="#AB30C5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1083_4204"
          x1="7.65784"
          y1="29.5946"
          x2="7.65784"
          y2="14.5752"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0000D4" />
          <stop offset="0.1452" stopColor="#1708D4" />
          <stop offset="0.4499" stopColor="#521DD5" />
          <stop offset="0.8854" stopColor="#AF3ED7" />
          <stop offset="1" stopColor="#C947D7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1083_4204"
          x1="14.3936"
          y1="25.117"
          x2="14.3936"
          y2="14.806"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0000D4" />
          <stop offset="0.0893" stopColor="#2912D8" />
          <stop offset="0.2117" stopColor="#5A28DD" />
          <stop offset="0.3374" stopColor="#843BE2" />
          <stop offset="0.4642" stopColor="#A64AE6" />
          <stop offset="0.5925" stopColor="#C156E8" />
          <stop offset="0.7231" stopColor="#D45EEA" />
          <stop offset="0.8571" stopColor="#DF63EC" />
          <stop offset="1" stopColor="#E365EC" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1083_4204"
          x1="21.1413"
          y1="26.0312"
          x2="21.1413"
          y2="7.73351"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AB30C5" />
          <stop offset="1" stopColor="#E365EC" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default WebReportsIcon;
