import { INavStyleProps, INavStyles, IStyleFunction } from "@fluentui/react";

export const anchorNavStyles: IStyleFunction<INavStyleProps, INavStyles> = (
  navStyleProps: INavStyleProps
) => {
  let navStyle: Partial<INavStyles> = {
    link: {
      paddingLeft: "20px",
      backgroundColor: navStyleProps.isSelected
        ? navStyleProps.theme.palette.themeLighter
        : undefined,
      selectors: {
        "&:hover": {
          backgroundColor: navStyleProps.isSelected
            ? `${navStyleProps.theme.palette.themeLighter} !important`
            : undefined,
          color: navStyleProps.isSelected
            ? `${navStyleProps.theme.palette.black} !important`
            : undefined,
        },
      },
    },
    groupContent: {
      margin: "0",
    },
  };

  return navStyle;
};
