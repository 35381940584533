import React from 'react';

interface iconProps {
  height?: string;
  width?: string;
  color?: string;
}

export const DepartmentIcon = (props: iconProps): JSX.Element => {
  return (
    <svg
      width={props.width ? props.width : '27'}
      height={props.height ? props.height : '25'}
      viewBox="0 0 27 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27 21.6609V5.64771C27 4.566 26.1475 3.68829 25.0949 3.68829H22.5126V22.2416C22.5126 23.9117 22.9398 24.2476 23.7267 24.5332C24.3079 24.7438 25.1355 24.6755 25.1355 24.6755C26.2361 24.1432 27 22.995 27 21.6609Z"
        fill={props.color || '#189A55'}
      />
      <path
        d="M24.0837 22.2416V3.68733V1.95943C24.0837 0.87676 23.2313 0 22.1786 0H1.90511C0.852457 0 0 0.87676 0 1.95943V23.0368C0 24.1185 0.852457 24.9962 1.90511 24.9962H1.97523H23.5745C24.4971 25.0408 24.9934 24.7467 25.3615 24.5559C25.3615 24.5559 24.0837 24.8501 24.0837 22.2416Z"
        fill={props.color || '#36C281'}
      />
      <path
        d="M12.3689 7.76085C13.7416 7.76085 14.8543 6.61637 14.8543 5.20458C14.8543 3.79279 13.7416 2.64831 12.3689 2.64831C10.9963 2.64831 9.88351 3.79279 9.88351 5.20458C9.88351 6.61637 10.9963 7.76085 12.3689 7.76085Z"
        fill="white"
        fillOpacity="0.70"
      />
      <path
        d="M12.3689 20.49C13.7416 20.49 14.8543 19.3455 14.8543 17.9337C14.8543 16.5219 13.7416 15.3775 12.3689 15.3775C10.9963 15.3775 9.88351 16.5219 9.88351 17.9337C9.88351 19.3455 10.9963 20.49 12.3689 20.49Z"
        fill="white"
        fillOpacity="0.70"
      />
      <path
        d="M18.945 20.49C20.3177 20.49 21.4304 19.3455 21.4304 17.9337C21.4304 16.5219 20.3177 15.3775 18.945 15.3775C17.5724 15.3775 16.4596 16.5219 16.4596 17.9337C16.4596 19.3455 17.5724 20.49 18.945 20.49Z"
        fill="white"
        fillOpacity="0.70"
      />
      <path
        d="M5.79376 20.49C7.16641 20.49 8.27917 19.3455 8.27917 17.9337C8.27917 16.5219 7.16641 15.3775 5.79376 15.3775C4.4211 15.3775 3.30835 16.5219 3.30835 17.9337C3.30835 19.3455 4.4211 20.49 5.79376 20.49Z"
        fill="white"
        fillOpacity="0.70"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9H13V11.1958H19.4847V14.6373H18.4053V12.306H6.33347V14.3413H5.25406V11.1958H12V9Z"
        fill="white"
        fillOpacity="0.70"
      />
    </svg>
  );
};
