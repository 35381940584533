import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRole } from "../types/types";

export type UserRoleState = {
  userRole: UserRole | null;
  isLoading: boolean;
  email: string;
};

export const userRoleSlice = createSlice({
  name: "userRole",
  initialState: {
    userRole: null,
    email: null,
    isLoading: false,
  } as UserRoleState,
  reducers: {
    setUserRoleAndEmail: (s, a: PayloadAction<any>) => {
      s.userRole = a.payload.userRole;
      s.email = a.payload.email;
    },
    setIsLoading: (s, a: PayloadAction<UserRoleState["isLoading"]>) => {
      s.isLoading = a.payload;
    },
  },
});

export const userRoleReducer = userRoleSlice.reducer;

export const { setUserRoleAndEmail, setIsLoading: setIsLoadingUserRole } =
  userRoleSlice.actions;
