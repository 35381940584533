import {
  DefaultButton,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogType,
  PrimaryButton,
  TextField,
  mergeStyleSets,
} from "@fluentui/react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setClientCampaignStatus } from "../../../api/campaignApi";
import { toast } from "../../../components/FluentToast";
import { Flex } from "../../../components/styled";
import { useSelector } from "../../../store/hooks";
import { loadLocationsForClientRoutine } from "../../../store/locations/locations";
import { handleAxiosError } from "../../../store/util";
import { ICampaignInfo } from "../../../types/types";

type Props = {
  actionType: string;
  campaignInfo: ICampaignInfo;
  toggleCampaignDialog: () => void;
  getCampaignData: () => void;
};

function ConfirmActionFormDialog({
  campaignInfo,
  actionType,
  toggleCampaignDialog,
  getCampaignData,
}: Props) {
  const clientId = useSelector((s) => s.clients.clientId);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState("");

  const campaignClosed = campaignInfo?.isCampaignClosed || !campaignInfo;

  const [showDialog, setShowDialog] = useState(false);

  const handleSetCampaignStatus = async () => {
    try {
      setLoading(true);
      await setClientCampaignStatus({
        clientId,
        shouldOpen: campaignInfo?.isCampaignClosed,
        data: {
          comment,
          rowVersion: campaignInfo?.rowVersion,
        },
      });
      toast.success(t("rvm.success"));
      getCampaignData();

      toggleDialog();
      toggleCampaignDialog();

      dispatch(
        loadLocationsForClientRoutine.trigger({
          clientId,
        })
      );
    } catch (error: any) {
      handleAxiosError(error);
    } finally {
      setLoading(false);
    }
  };

  const toggleDialog = useCallback(() => {
    setShowDialog((prev) => !prev);
  }, []);

  const noCommentValue = !comment.length;

  const importantInfo = campaignClosed
    ? t("rvm.campaignOpenImportantInfo.label")
    : t("rvm.campaignCloseImportantInfo.label");

  const warningInfo = campaignClosed
    ? t("rvm.campaignOpenWarningInfo.label")
    : t("rvm.campaignCloseWarningInfo.label");

  return (
    <>
      <PrimaryButton
        disabled={loading}
        text={actionType}
        onClick={toggleDialog}
        allowDisabledFocus={true}
      />
      <Dialog
        hidden={!showDialog}
        onDismiss={toggleDialog}
        dialogContentProps={{
          type: DialogType.normal,
          title: <div>{t(actionType)}</div>,

          showCloseButton: true,
        }}
        minWidth={400}
        modalProps={{
          isBlocking: true, //phraseActivated ? false :
        }}
      >
        <DialogContent title={importantInfo}>
          <TextField
            name="comment"
            type="text"
            label={t("rvm.clientComment.label")}
            multiline
            maxLength={240}
            errorMessage={noCommentValue ? t("greco.required") : null}
            rows={4}
            onChange={(ev, value) => setComment(value)}
          />
          <Flex>
            <p className={classes.warningInfoTitle}>
              {t("rvm.campaignWarning.label")}
            </p>
            <p className={classes.warningInfoBody}>{warningInfo}</p>
          </Flex>
        </DialogContent>
        <DialogFooter>
          <Flex justifyContent="center" gap={15}>
            <PrimaryButton
              disabled={loading || noCommentValue}
              text={t("greco.yes")}
              onClick={handleSetCampaignStatus}
              allowDisabledFocus={true}
            />
            <DefaultButton
              disabled={loading}
              text={t("greco.no")}
              onClick={toggleDialog}
              allowDisabledFocus
            />
          </Flex>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default ConfirmActionFormDialog;

const classes = mergeStyleSets({
  warningInfoTitle: {
    color: "rgb(164, 38, 44)",
    fontSize: 16,
    marginTop: 10,
    textTransform: "uppercase",
  },
  warningInfoBody: {
    color: "rgb(164, 38, 44)",
    fontSize: 14,
    marginTop: 5,
  },
});
