import i18n from "i18next";
import PhraseInContextEditorPostProcessor from "i18next-phrase-in-context-editor-post-processor";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { UserDataLoader } from "./api/UserDataLoader";

const defaultLanguage = "en-GB";

// creating a language detection plugin
// http://i18next.com/docs/ownplugin/#languagedetector
const languageDetector = {
  type: "languageDetector" as any, // Exact type is 'backend' | 'logger' | 'languageDetector' | 'postProcessor' | 'i18nFormat' | '3rdParty'
  async: true, // flags below detection to be async
  detect: (callback: Function) => {
    return UserDataLoader.getUserData()
      .then((userData) =>
        callback(userData.preferredLanguage || defaultLanguage)
      )
      .catch((error) => callback(defaultLanguage));
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

export const inContextEditorPostProcessor =
  new PhraseInContextEditorPostProcessor({
    // phraseEnabled: !!queryString.parse(window.location.search).phrase_context,
    phraseEnabled: false,
    // @ts-ignore
    useOldICE: true,
    projectId: process.env.REACT_APP_PHRASE_ID,
    autoLowercase: false,
  });

i18n
  .use(Backend)
  .use(languageDetector)
  .use(initReactI18next)
  .use(inContextEditorPostProcessor)
  .init({
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${process.env.REACT_APP_PHRASE_BASE_URL}/{{lng}}.json`,
    },
    fallbackLng: (code) => {
      if (!code || code === "en-US") return ["en-GB", "de-AT"];

      if (code === "de-DE") return ["de-AT", "en-GB"];
      if (code === "de-CH") return ["de-AT", "en-GB"];
      if (code === "de-AT") return ["de-AT", "en-GB"];

      return ["en-GB", "de-AT"];
    },
    saveMissing: true,
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
      // Check if the key is available in 'en-GB', and if so, return its value

      if (lngs[1] === "en-GB") {
        return i18n.t(key, { lng: "en-GB" });
      }

      // If not found in 'en-GB', return the fallback value
      // console.warn(`Missing translation key: ${key}`);
      return fallbackValue;
    },
    supportedLngs: [
      "en-GB",
      "de-AT",
      "bg-BG",
      "cs-CZ",
      "et-EE",
      "hr-HR",
      "hu-HU",
      "lt-LT",
      "pl-PL",
      "ro-RO",
      "ru-RU",
      "sr-Latn-RS",
      "sk-SK",
      "sl-SI",
      "tr-TR",
      "uk-UA",
    ],

    postProcess: ["phraseInContextEditor"],
  });

export default i18n;
