export const zIndex = {
  leftSidebar: 25100,
  leftSidebarButton: 25100,
  rightSidebar: 25102,
  rightSidebarButton: 25103,
  mapPageSearch: 25001,
  notification: 99999999,
  modal: 103,
  pageSpinner: 104,
  mapPageLoadingOverlay: 98,
  topMenu: 25101,
  bottomMenu: 25004,
};
